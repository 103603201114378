import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import AddPhotoModal from './AddPhotoModal';

// Importy obrázků

import nandezudImage from '../assets/images/nandezu.png';
import loveImage from '../assets/images/love.png';
import subsImage from '../assets/images/subs.png';

const BASE_URL = 'https://app-tdh1.onrender.com';

// Konstanty pro pozice a velikosti tlačítek
const LOVE_BUTTON_TOP = '2.7%';
const LOVE_BUTTON_RIGHT = '5%';
const LOVE_BUTTON_SIZE = '23px';

const SUBS_BUTTON_TOP = '2.7%';
const SUBS_BUTTON_LEFT = '5%';
const SUBS_BUTTON_SIZE = '26px';

const NANDEZUD_BUTTON_TOP = '3%';
const NANDEZUD_BUTTON_WIDTH = '130px';

// Konstanty pro profilové obrázky
const PROFILE_IMAGES_TOP = '20%';
const PROFILE_IMAGE_HEIGHT = '50%';
const PROFILE_IMAGE_WIDTH = '60%';
const PROFILE_IMAGE_GAP = '30%';
const PROFILE_IMAGE_BORDER_RADIUS = '20px';
const PROFILE_IMAGES_LEFT_OFFSET = '18%';

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
`;

const Button = styled.button`
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  transition: opacity 0.1s ease, transform 0.1s ease;
  
  &:focus, &:active, &:hover {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  &:active {
    opacity: 0.8;
    transform: scale(0.95);
  }
  
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: transparent;
  }
`;

const LoveButton = styled(Button)`
  top: ${LOVE_BUTTON_TOP};
  right: ${LOVE_BUTTON_RIGHT};
  width: ${LOVE_BUTTON_SIZE};
  height: ${LOVE_BUTTON_SIZE};
`;

const SubsButton = styled(Button)`
  top: ${SUBS_BUTTON_TOP};
  left: ${SUBS_BUTTON_LEFT};
  width: ${SUBS_BUTTON_SIZE};
  height: ${SUBS_BUTTON_SIZE};
`;

const NandezudButton = styled(Button)`
  top: ${NANDEZUD_BUTTON_TOP};
  left: 50%;
  transform: translateX(-50%);
  width: ${NANDEZUD_BUTTON_WIDTH};

  &:active {
    transform: translateX(-50%) scale(0.95);
  }
`;

const ButtonImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
`;

const ProfileImagesContainer = styled.div`
  position: absolute;
  top: ${PROFILE_IMAGES_TOP};
  left: 0;
  right: 0;
  height: ${PROFILE_IMAGE_HEIGHT};
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  gap: ${PROFILE_IMAGE_GAP};
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.5) transparent;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 3px;
  }
  padding: 0 2.5%;
`;

const ProfileImageWrapper = styled.div<{ isFirst: boolean }>`
  flex: 0 0 ${PROFILE_IMAGE_WIDTH};
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: ${PROFILE_IMAGE_BORDER_RADIUS};
  margin-left: ${props => props.isFirst ? PROFILE_IMAGES_LEFT_OFFSET : '0'};
  display: flex;
  flex-direction: column;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 90%;
  object-fit: cover;
`;

const SetAsMainButton = styled.button`
  margin-top: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

const DeleteButton = styled.button`
  margin-top: 5px;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 0, 0, 0.9);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const ActiveImageText = styled.p`
  margin-top: 5px;
  color: white;
  text-align: center;
  font-size: 12px;
`;

const AddPhotoButton = styled(Button)`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 180px;
  height: 45px;
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  border: none;
  border-radius: 22px;
  font-size: 14px;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.7);
    transform: translateX(-50%) scale(0.95);
  }
`;

const MessageBox = styled.div<{ isError: boolean }>`
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  color: ${props => props.isError ? 'red' : 'white'};
  padding: 10px 20px;
  font-size: 14px;
  text-align: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  
  &.visible {
    opacity: 1;
  }
`;

interface User {
  id: string;
  username: string;
  email: string;
  subscription_type: string;
  profile_images: string[];
}

const ProfileScreen: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId, token } = location.state as { userId: string; token: string };
  
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isAddPhotoModalOpen, setIsAddPhotoModalOpen] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);

  const getToken = async () => {
    // V reálné aplikaci by zde byla implementace pro získání tokenu
    // Pro účely tohoto příkladu prostě vrátíme token ze stavu
    return token;
  };

  const fetchUserData = useCallback(async () => {
    try {
      if (!token || !userId) throw new Error('User not authenticated');
      const response = await axios.get(`${BASE_URL}/users/${userId}/`, {
        headers: { 'Authorization': `Token ${token}` }
      });
      if (response.data) {
        setUser(response.data);
      } else {
        throw new Error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      showMessage('Failed to load user data.', true);
    }
  }, [token, userId]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleButtonClick = (buttonName: string) => {
    console.log(`${buttonName} button clicked`);
    switch (buttonName) {
      case 'Love':
        navigate('/manage-profile-pictures', { state: { token, userId } });
        break;
      case 'Subs':
        navigate('/profile-set', { state: { token, userId } });
        break;
      case 'Nandezud':
        navigate('/user', { state: { token, userId } });
        break;
    }
  };

  const handleAddNewPhoto = () => {
    setIsAddPhotoModalOpen(true);
  };

  const showMessage = (message: string, isError: boolean) => {
    if (isError) {
      setError(message);
    } else {
      setSuccessMessage(message);
    }
    setMessageVisible(true);
    setTimeout(() => {
      setMessageVisible(false);
      setError(null);
      setSuccessMessage(null);
    }, 3000);
  };

  const handlePhotoAdded = useCallback((success: boolean, message: string) => {
    showMessage(message, !success);
    if (success) {
      fetchUserData();
    }
    setIsAddPhotoModalOpen(false);
  }, [fetchUserData]);

  const setActiveProfileImage = async (imageUrl: string) => {
    try {
      const userToken = await getToken();
      if (!userToken) {
        throw new Error('User not authenticated');
      }
      const response = await axios.post(
        `${BASE_URL}/user/users/${userId}/set_active_profile_image/`,
        { image_url: imageUrl },
        {
          headers: {
            'Authorization': `Token ${userToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.status === 200) {
        console.log('Active profile image set successfully');
        fetchUserData();
        showMessage('Main profile image updated successfully', false);
      } else {
        console.log('Failed to set active profile image:', response.data);
        showMessage('Failed to set active profile image.', true);
      }
    } catch (error) {
      console.error('Error setting active profile image:', error);
      showMessage('Error setting active profile image.', true);
    }
  };

  const deleteProfileImage = async (imageUrl: string) => {
    try {
      const userToken = await getToken();
      if (!userToken) {
        throw new Error('User not authenticated');
      }

      const response = await axios.delete(
        `${BASE_URL}/user/users/${userId}/remove_profile_image/`,
        {
          headers: {
            'Authorization': `Token ${userToken}`
          },
          data: { image_url: imageUrl }
        }
      );

      if (response.status === 200) {
        console.log('Profile image deleted successfully');
        fetchUserData();
        showMessage('Profile image deleted successfully', false);
      } else {
        console.log('Failed to delete profile image:', response.data);
        showMessage('Failed to delete profile image.', true);
      }
    } catch (error) {
      console.error('Error deleting profile image:', error);
      showMessage('Error deleting profile image.', true);
    }
  };

  return (
    <Container>
      <LoveButton onClick={() => handleButtonClick('Love')}>
        <ButtonImage src={loveImage} alt="Love" />
      </LoveButton>
      <SubsButton onClick={() => handleButtonClick('Subs')}>
        <ButtonImage src={subsImage} alt="Subs" />
      </SubsButton>
      <NandezudButton onClick={() => handleButtonClick('Nandezud')}>
        <ButtonImage src={nandezudImage} alt="Nandezud" />
      </NandezudButton>
      
      {user && user.profile_images.length > 0 && (
        <ProfileImagesContainer>
          {user.profile_images.map((imageUrl, index) => (
            <ProfileImageWrapper key={index} isFirst={index === 0}>
              <ProfileImage src={imageUrl} alt={`Profile ${index + 1}`} />
              {index === 0 ? (
                <ActiveImageText>Active Profile Picture</ActiveImageText>
              ) : (
                <ButtonContainer>
                  <SetAsMainButton onClick={() => setActiveProfileImage(imageUrl)}>
                    Set as Main
                  </SetAsMainButton>
                  <DeleteButton onClick={() => deleteProfileImage(imageUrl)}>
                    Delete
                  </DeleteButton>
                </ButtonContainer>
              )}
            </ProfileImageWrapper>
          ))}
        </ProfileImagesContainer>
      )}
      
      <AddPhotoButton onClick={handleAddNewPhoto}>
        Add New Photo
      </AddPhotoButton>

      {isAddPhotoModalOpen && (
        <AddPhotoModal
          userId={userId}
          token={token}
          onClose={() => setIsAddPhotoModalOpen(false)}
          onPhotoAdded={handlePhotoAdded}
        />
      )}

      <MessageBox isError={!!error} className={messageVisible ? 'visible' : ''}>
        {error || successMessage}
      </MessageBox>
    </Container>
  );
};

export default ProfileScreen;