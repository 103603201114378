import React, { createContext, useState, useContext, ReactNode } from 'react';

interface SubscriptionLimits {
  virtual_try_ons_remaining: number | null;
  profile_images_remaining: number | null;
  try_on_results_remaining: number | null;
}

interface SubscriptionContextType {
  virtualTryOnsRemaining: number | null;
  profileImagesRemaining: number | null;
  tryOnResultsRemaining: number | null;
  updateSubscriptionLimits: (limits: SubscriptionLimits) => void;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export const useSubscription = (): SubscriptionContextType => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return context;
};

interface SubscriptionProviderProps {
  children: ReactNode;
}

export const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({ children }) => {
  const [virtualTryOnsRemaining, setVirtualTryOnsRemaining] = useState<number | null>(null);
  const [profileImagesRemaining, setProfileImagesRemaining] = useState<number | null>(null);
  const [tryOnResultsRemaining, setTryOnResultsRemaining] = useState<number | null>(null);

  const updateSubscriptionLimits = (limits: SubscriptionLimits) => {
    setVirtualTryOnsRemaining(limits.virtual_try_ons_remaining);
    setProfileImagesRemaining(limits.profile_images_remaining);
    setTryOnResultsRemaining(limits.try_on_results_remaining);
  };

  return (
    <SubscriptionContext.Provider 
      value={{ 
        virtualTryOnsRemaining, 
        profileImagesRemaining, 
        tryOnResultsRemaining,
        updateSubscriptionLimits 
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};