import React from 'react';

const PrivacyPolicy: React.FC = () => (
  <div>
    <h1>Privacy Policy</h1>
    
    <p>Last updated: [25.08.2024]</p>
    
    <h2>1. Introduction</h2>
    <p>At Nandezu Inc. ("we", "us", "our"), we are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our fashion try-on application and related services ("the App").</p>
    <p>We understand the importance of your personal data and are dedicated to maintaining its confidentiality and security. This policy is designed to help you understand what information we collect, why we collect it, and how you can update, manage, export, and delete your information.</p>

    <h2>2. Company Information</h2>
    <p>
      Nandezu Inc.<br />
      Address: Werqwise San Mateo, CA 94402<br />
      4 West 4th Ave San Francisco CA<br />
      Email: info@nandezu.com
    </p>

    <h2>3. Information We Collect</h2>
    <p>We collect several types of information to provide and improve our service:</p>
    
    <h3>3.1 Personal Identification Information</h3>
    <p>This may include, but is not limited to:</p>
    <ul>
      <li>Email address</li>
      <li>First name and last name</li>
      <li>Phone number (optional)</li>
      <li>Address, State, Province, ZIP/Postal code, City</li>
    </ul>

    <h3>3.2 Photos and Images</h3>
    <p>Our App allows you to upload photos for the purpose of virtual try-on of clothing items. These photos are:</p>
    <ul>
      <li>Used solely for the virtual try-on feature within the App</li>
      <li>Encrypted and securely stored on our servers</li>
      <li>Not shared with third parties</li>
      <li>Processed using our proprietary AI algorithms for virtual clothing fitting</li>
    </ul>

    <h3>3.3 Usage Data</h3>
    <p>We collect information on how the App is accessed and used, including:</p>
    <ul>
      <li>Frequency and duration of use</li>
      <li>Pages of the App that you visit</li>
      <li>Time and date of your visits</li>
      <li>Unique device identifiers</li>
      <li>Mobile device information (type of device, operating system)</li>
      <li>Internet Protocol (IP) address</li>
      <li>Browser type and version</li>
      <li>Time zone setting and location</li>
      <li>Crash reports and system activity</li>
    </ul>

    <h3>3.4 Shopping and Preference Data</h3>
    <p>To enhance your experience and provide personalized services, we collect data about your shopping behavior and preferences, including:</p>
    <ul>
      <li>Clothing items you've tried on virtually</li>
      <li>Favorite clothing brands and items</li>
      <li>Color preferences</li>
      <li>Size information</li>
      <li>Style preferences</li>
      <li>Frequency of use of specific features</li>
      <li>Purchase history (if applicable)</li>
      <li>Wishlist items</li>
    </ul>

    <h3>3.5 Location Data</h3>
    <p>We collect approximate location data (such as state or country) to:</p>
    <ul>
      <li>Provide location-specific services</li>
      <li>Recommend relevant clothing based on local weather and trends</li>
      <li>Improve our App's functionality and user experience</li>
    </ul>
    <p>This location data is collected at the state or country level and does not pinpoint your exact location.</p>

    <h3>3.6 Cookies and Tracking Technologies</h3>
    <p>We use cookies and similar tracking technologies to track activity on our App and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>

    <h2>4. How We Use Your Information</h2>
    <p>We use the collected data for various purposes:</p>
    <ul>
      <li>To provide and maintain our App</li>
      <li>To personalize your virtual try-on experience</li>
      <li>To improve our AI algorithms for more accurate clothing fittings</li>
      <li>To notify you about changes to our App</li>
      <li>To allow you to participate in interactive features of our App when you choose to do so</li>
      <li>To provide customer support and respond to your inquiries</li>
      <li>To gather analysis or valuable information so that we can improve our App</li>
      <li>To monitor the usage of our App</li>
      <li>To detect, prevent and address technical issues</li>
      <li>To personalize your shopping experience and provide tailored recommendations</li>
      <li>To analyze shopping trends and preferences for product development</li>
      <li>To comply with legal obligations</li>
    </ul>

    <h2>5. Legal Basis for Processing Personal Data under GDPR</h2>
    <p>If you are from the European Economic Area (EEA), our legal basis for collecting and using your personal information depends on the specific data and the context in which we collect it. We may process your personal data because:</p>
    <ul>
      <li>We need to perform a contract with you</li>
      <li>You have given us permission to do so</li>
      <li>The processing is in our legitimate interests and it's not overridden by your rights</li>
      <li>To comply with the law</li>
    </ul>

    <h2>6. Data Retention</h2>
    <p>We will retain your personal data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your data to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.</p>
    <p>Your usage data will be retained for internal analysis purposes. This data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our App, or we are legally obligated to retain this data for longer time periods.</p>

    <h2>7. Data Security and Storage</h2>
    <p>We prioritize the security of your data:</p>
    <ul>
      <li>All data, including photos, are encrypted both in transit and at rest using industry-standard encryption protocols.</li>
      <li>We implement and maintain appropriate technical and organizational security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.</li>
      <li>Access to your personal data is restricted to authorized personnel only, operating under strict confidentiality obligations.</li>
      <li>We regularly review and update our security practices to enhance data protection.</li>
      <li>While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security. No method of transmission over the Internet or method of electronic storage is 100% secure.</li>
    </ul>

    <h2>8. Data Sharing and Third Parties</h2>
    <p>We may share aggregated, non-personally identifiable information publicly and with our partners, advertisers, or connected sites. This data does not contain any personal information and is used to develop content and services and for general business analysis. Examples of shared aggregated data include:</p>
    <ul>
      <li>Shopping behavior trends</li>
      <li>User engagement statistics</li>
      <li>Demographic information</li>
      <li>Clothing and style preferences</li>
      <li>General location data (state or country level)</li>
    </ul>
    <p>We may also share your data with third parties in the following situations:</p>
    <ul>
      <li>With your consent</li>
      <li>For legal reasons, if we believe disclosure is required by law</li>
      <li>To affiliated companies within our corporate group</li>
      <li>To our trusted service providers who work on our behalf and have agreed to adhere to the rules set forth in this privacy policy</li>
    </ul>

    <h2>9. Your Data Protection Rights</h2>
    <p>We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data. You have the following data protection rights:</p>
    <ul>
      <li><strong>Access:</strong> You can request copies of your personal data.</li>
      <li><strong>Rectification:</strong> You can request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
      <li><strong>Erasure:</strong> You can request that we erase your personal data, under certain conditions.</li>
      <li><strong>Restrict processing:</strong> You can request that we restrict the processing of your personal data, under certain conditions.</li>
      <li><strong>Object to processing:</strong> You can object to our processing of your personal data, under certain conditions.</li>
      <li><strong>Data portability:</strong> You can request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
      <li><strong>Withdraw consent:</strong> You can withdraw your consent at any time where we relied on your consent to process your personal information.</li>
    </ul>
    <p>If you wish to exercise any of these rights, please contact us using the contact information provided in this policy. We will respond to your request within one month.</p>

    <h2>10. California Privacy Rights (CCPA)</h2>
    <p>If you are a California resident, you have specific rights regarding your personal information under the California Consumer Privacy Act (CCPA). These rights include:</p>
    <ul>
      <li>The right to know what personal information we collect, use, disclose, and sell</li>
      <li>The right to request deletion of your personal information</li>
      <li>The right to opt-out of the sale of your personal information</li>
      <li>The right to non-discrimination for exercising your CCPA rights</li>
    </ul>
    <p>To exercise your rights under CCPA, please contact us using the information provided at the end of this policy.</p>

    <h2>11. Children's Privacy</h2>
    <p>Our App does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us. If we become aware that we have collected personal data from children without verification of parental consent, we take steps to remove that information from our servers.</p>

    <h2>12. Changes to This Privacy Policy</h2>
    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

    <h2>13. International Data Transfers</h2>
    <p>Your information, including personal data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. If you are located outside the United States and choose to provide information to us, please note that we transfer the data, including personal data, to the United States and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>

    <h2>14. Contact Us</h2>
    <p>If you have any questions about this Privacy Policy, please contact us:</p>
    <p>
      Nandezu Inc.<br />
      Address: Werqwise San Mateo, CA 94402<br />
      4 West 4th Ave San Francisco CA<br />
      Email: info@nandezu.com
    </p>
  </div>
);

export default PrivacyPolicy;