import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  ImageSourcePropType,
  Alert,
  Animated,
  Platform,
  Easing,
  ScrollView,
  
} from 'react-native';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSubscription } from '../contexts/SubscriptionContext';

// Import obrázků a animací

import nandezudImage from '../assets/images/nandezu.png';
import loveImage from '../assets/images/love.png';
import subsImage from '../assets/images/subs.png';

// Import nových obrázků pro kategorie
import dressoImage from '../assets/images/dresso.png';
import topsImage from '../assets/images/tops.png';
import shortsImage from '../assets/images/shorts.png';
import jumpsuitsImage from '../assets/images/jumpsuit.png';
import jacketImage from '../assets/images/jacket.png';
import jeansImage from '../assets/images/jeanss.png';
import pantsImage from '../assets/images/pants.png';
import skirtsImage from '../assets/images/skirts.png';
import sweaterImage from '../assets/images/sweater.png';
import legginsImage from '../assets/images/leggins.png';
import coatImage from '../assets/images/coat.png';

const BASE_URL = 'https://app-tdh1.onrender.com';

// Získání rozměrů obrazovky


// Definice pevných rozměrů obsahu
const CONTENT_WIDTH = 420;
const CONTENT_HEIGHT = 812;

const supportsClipPath = CSS.supports("clip-path", "inset(0 round 10px)");

interface User {
  id: string;
  username: string;
  email: string;
  subscription_type: string;
  profile_images: string[];
}

interface TryOnResult {
  id: string;
  result_image: string;
  created_at: string;
}

interface CategoryButton {
  id: string;
  image: ImageSourcePropType;
  screen: string;
  style: {
    width: number;
    height: number;
    marginRight: number;
    top: number;
  };
}

const categoryButtons: CategoryButton[] = [
  { id: 'dresso', image: dressoImage as ImageSourcePropType, screen: '/dress-screen', style: { width: 270, height: 270, marginRight: -70, top: 487 } },
  { id: 'tops', image: topsImage as ImageSourcePropType, screen: '/top-screen', style: { width: 266, height: 266, marginRight: -89, top: 494 } },
  { id: 'shorts', image: shortsImage as ImageSourcePropType, screen: '/short-screen', style: { width: 310, height: 310, marginRight: -90, top: 477 } },
  { id: 'jumpsuits', image: jumpsuitsImage as ImageSourcePropType, screen: '/jumpsuit-screen', style: { width: 267, height: 267, marginRight: -65, top: 491 } },
  { id: 'jacket', image: jacketImage as ImageSourcePropType, screen: '/jacket-screen', style: { width: 270, height: 270, marginRight: -89, top: 490 } },
  { id: 'jeans', image: jeansImage as ImageSourcePropType, screen: '/jeans-screen', style: { width: 296, height: 296, marginRight: -98, top: 484 } },
  { id: 'pants', image: pantsImage as ImageSourcePropType, screen: '/pants-screen', style: { width: 296, height: 296, marginRight: -98, top: 485 } },
  { id: 'skirts', image: skirtsImage as ImageSourcePropType, screen: '/skirt-screen', style: { width: 290, height: 290, marginRight: -89, top: 492 } },
  { id: 'sweater', image: sweaterImage as ImageSourcePropType, screen: '/sweater-screen', style: { width: 282, height: 282, marginRight: -97, top: 482 } },
  { id: 'leggins', image: legginsImage as ImageSourcePropType, screen: '/leggins-screen', style: { width: 300, height: 300, marginRight: -89, top: 477} },
  { id: 'coat', image: coatImage as ImageSourcePropType, screen: '/coat-screen', style: { width: 280, height: 280, marginRight: 10, top: 488 } },
];

const AllScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams<{ userId: string }>();
  const { token } = location.state as { token: string };

  const { updateSubscriptionLimits } = useSubscription();

  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [tryOnResults, setTryOnResults] = useState<TryOnResult[]>([]);
  const [sortedTryOnResults, setSortedTryOnResults] = useState<TryOnResult[]>([]);
  const [enlargedImage, setEnlargedImage] = useState<TryOnResult | null>(null);
  const [isEnlarged, setIsEnlarged] = useState(false);

  const enlargeAnimation = useRef(new Animated.Value(0)).current;
  const fadeAnimation = useRef(new Animated.Value(1)).current;
  const screenFadeAnim = useRef(new Animated.Value(1)).current;
  const containerHeightAnimation = useRef(new Animated.Value(400)).current;
  const allButtonAnimation = useRef(new Animated.Value(1)).current;
  const hideShowAnimation = useRef(new Animated.Value(1)).current;

  const fetchUserData = useCallback(async () => {
    try {
      if (!token || !userId) throw new Error('User not authenticated');

      const response = await axios.get(`${BASE_URL}/users/${userId}/`, {
        headers: { 'Authorization': `Token ${token}` }
      });

      if (response.data) {
        setUser(response.data);
      } else {
        throw new Error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to load user data.');
    }
  }, [token, userId]);

  const fetchTryOnResults = useCallback(async () => {
    try {
      if (!token) throw new Error('User not authenticated');

      const response = await axios.get(`${BASE_URL}/tryon/results/`, {
        headers: { 'Authorization': `Token ${token}` }
      });
     
      setTryOnResults(response.data);
    } catch (error) {
      console.error('Error fetching try-on results:', error);
    }
  }, [token]);

  const fetchSubscriptionDetails = useCallback(async () => {
    try {
      if (!token) throw new Error('User not authenticated');

      const response = await axios.get(`${BASE_URL}/subscription/details/`, {
        headers: { 'Authorization': `Token ${token}` }
      });

      updateSubscriptionLimits(response.data);
    } catch (error) {
      console.error('Error fetching subscription details:', error);
    }
  }, [token, updateSubscriptionLimits]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchUserData(), fetchTryOnResults(), fetchSubscriptionDetails()]);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data.');
      }
    };

    fetchData();
  }, [fetchUserData, fetchTryOnResults, fetchSubscriptionDetails]);

  useEffect(() => {
    const sorted = [...tryOnResults].sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
    setSortedTryOnResults(sorted);
  }, [tryOnResults]);

  const handleButtonClick = useCallback((buttonName: string, screen?: string) => {
    if (!userId) {
      console.error('UserId is missing');
      return;
    }

    Animated.timing(screenFadeAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      switch (buttonName) {
        case 'Love':
          navigate(`/manage-profile-picture/${userId}`, { state: { token, userId } });
          break;
        case 'Subs':
          navigate(`/menu-screen/${userId}`, { state: { token, userId } });
          break;
        case 'Nandezud':
        case 'All':
          navigate(`/user/${userId}`, { state: { token } });
          break;
        default:
          if (screen) {
            navigate(`${screen}/${userId}`, { state: { token, userId } });
          }
      }
    });
  }, [navigate, token, userId, screenFadeAnim]);

  const animateReturnToOriginalState = useCallback(() => {
    setIsEnlarged(false);
    setEnlargedImage(null);

    Animated.parallel([
      Animated.timing(enlargeAnimation, {
        toValue: 0,
        duration: 600,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.cubic)
      }),
      Animated.timing(fadeAnimation, {
        toValue: 1,
        duration: 600,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.cubic)
      }),
      Animated.timing(containerHeightAnimation, {
        toValue: 400,
        duration: 600,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.cubic)
      }),
      Animated.timing(hideShowAnimation, {
        toValue: 1,
        duration: 600,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.cubic)
      })
    ]).start();
  }, [enlargeAnimation, fadeAnimation, containerHeightAnimation, hideShowAnimation]);

  const handleTryOnImageClick = useCallback((result: TryOnResult) => {
    if (enlargedImage && enlargedImage.id === result.id) {
      animateReturnToOriginalState();
    } else {
      setEnlargedImage(result);
      setIsEnlarged(true);
      Animated.parallel([
        Animated.timing(enlargeAnimation, {
          toValue: 1,
          duration: 600,
          useNativeDriver: false,
          easing: Easing.inOut(Easing.cubic)
        }),
        Animated.timing(fadeAnimation, {
          toValue: 0,
          duration: 600,
          useNativeDriver: false,
          easing: Easing.inOut(Easing.cubic)
        }),
        Animated.timing(containerHeightAnimation, {
          toValue: 600,
          duration: 600,
          useNativeDriver: false,
          easing: Easing.inOut(Easing.cubic)
        }),
        Animated.timing(hideShowAnimation, {
          toValue: 0,
          duration: 600,
          useNativeDriver: false,
          easing: Easing.inOut(Easing.cubic)
        })
      ]).start();
    }
  }, [enlargedImage, enlargeAnimation, fadeAnimation, containerHeightAnimation, hideShowAnimation, animateReturnToOriginalState]);

  const handleDeleteImage = useCallback(async (resultId: string) => {
    try {
      if (!token) {
        throw new Error('User not authenticated');
      }

      const isConfirmed = await new Promise<boolean>((resolve) => {
        if (Platform.OS === 'web') {
          resolve(window.confirm("Are you sure you want to delete this image?"));
        } else {
          Alert.alert(
            "Confirmation",
            "Are you sure you want to delete this image?",
            [
              { text: "No", onPress: () => resolve(false), style: "cancel" },
              { text: "Yes", onPress: () => resolve(true) }
            ]
          );
        }
      });

      if (isConfirmed) {
        const response = await axios.delete(`${BASE_URL}/tryon/delete-result/${resultId}/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        if (response.status === 204 || response.status === 200) {
          setTryOnResults(prev => prev.filter(result => result.id !== resultId));
          animateReturnToOriginalState();
        } else {
          throw new Error(`Unexpected server response: ${response.status}`);
        }
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      Alert.alert('Error', 'Failed to delete the image. Please try again.');
    }
  }, [token, animateReturnToOriginalState]);

  const handleAllButtonPress = () => {
    Animated.sequence([
      Animated.timing(allButtonAnimation, {
        toValue: 0.8,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(allButtonAnimation, {
        toValue: 1,
        duration: 100,
        useNativeDriver: true,
      }),
    ]).start(() => {
      handleButtonClick('All');
    });
  };

  const renderCategoryButtons = () => (
    <ScrollView 
      horizontal 
      style={styles.categoryButtonsContainer}
      showsHorizontalScrollIndicator={false}
    >
      {categoryButtons.map((button) => (
        <TouchableOpacity
          key={button.id}
          style={[styles.categoryButton, button.style]}
          onPress={() => handleButtonClick(button.id, button.screen)}
        >
          <Image source={button.image} style={styles.categoryButtonImage} />
        </TouchableOpacity>
      ))}
    </ScrollView>
  );

  const enlargedStyles = {
    width: enlargeAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [210, 350]
    }),
    height: enlargeAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [320, 530]
    }),
    marginRight: enlargeAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [37, 30]
    }),
    zIndex: enlargeAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [1, 10]
    }),
  };

  if (error) {
    return (
      <View style={styles.centered}>
        <Text style={styles.errorText}>{error}</Text>
        <TouchableOpacity style={styles.retryButton} onPress={() => {
          setError(null);
          Promise.all([fetchUserData(), fetchTryOnResults(), fetchSubscriptionDetails()]);
        }}>
          <Text style={styles.retryButtonText}>Retry</Text>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <ScrollView 
        contentContainerStyle={styles.scrollViewContent}
        showsVerticalScrollIndicator={false}
      >
        
        <View style={styles.content}>
          <TouchableOpacity style={styles.loveButton} onPress={() => handleButtonClick('Love')}>
            <Image source={loveImage as ImageSourcePropType} style={styles.buttonImage} />
          </TouchableOpacity>
         
          <TouchableOpacity style={styles.subsButton} onPress={() => handleButtonClick('Subs')}>
            <Image source={subsImage as ImageSourcePropType} style={styles.buttonImage} />
          </TouchableOpacity>
         
          <TouchableOpacity style={styles.nandezudButton} onPress={() => handleButtonClick('Nandezud')}>
            <Image source={nandezudImage as ImageSourcePropType} style={styles.buttonImage} />
          </TouchableOpacity>

          <Animated.ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            style={[
              styles.tryOnResultsContainer,
              { height: containerHeightAnimation }
            ]}
            contentContainerStyle={styles.tryOnResultsContent}
          >
            {user && user.profile_images && user.profile_images.map((imageUrl, index) => (
              <View key={`profile-${index}`}>
                <Image
                  source={{ uri: imageUrl }}
                  style={styles.profileImage}
                />
              </View>
            ))}
            {sortedTryOnResults.map((result) => (
              <TouchableOpacity
                key={result.id}
                onPress={() => handleTryOnImageClick(result)}
              >
                <Animated.Image
                  source={{ uri: result.result_image }}
                  style={[styles.tryOnResultImage,
                    enlargedImage && enlargedImage.id === result.id ? enlargedStyles : {}
                  ]}
                />
                {isEnlarged && enlargedImage && enlargedImage.id === result.id && (
                  <TouchableOpacity
                    style={styles.deleteButton}
                    onPress={() => handleDeleteImage(result.id)}
                  >
                    <Text style={styles.deleteButtonText}>Delete</Text>
                  </TouchableOpacity>
                )}
              </TouchableOpacity>
            ))}
          </Animated.ScrollView>

          <Animated.View 
            style={[
              styles.categoriesSection, 
              { 
                opacity: hideShowAnimation,
                transform: [{ 
                  translateY: hideShowAnimation.interpolate({
                    inputRange: [0, 1],
                    outputRange: [50, 0]
                  }) 
                }] 
              }
            ]}
          >
            <TouchableOpacity 
              onPress={handleAllButtonPress}
              style={styles.allButton}
            >
              <Animated.Text 
                style={[
                  styles.categoriesText,
                  { transform: [{ scale: allButtonAnimation }] }
                ]}
              >
                ALL
              </Animated.Text>
            </TouchableOpacity>
            <View style={styles.categoriesLine} />
          </Animated.View>

          <Animated.View 
            style={[
              styles.categoryButtonsContainer,
              {
                opacity: hideShowAnimation,
                transform: [{ 
                  translateY: hideShowAnimation.interpolate({
                    inputRange: [0, 1],
                    outputRange: [100, 0]
                  }) 
                }]
              }
            ]}
          >
            {renderCategoryButtons()}
          </Animated.View>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#020209',
  },
  scrollViewContent: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '130%',
    height: '130%',
    resizeMode: 'cover',
  },
  content: {
    width: CONTENT_WIDTH,
    height: CONTENT_HEIGHT,
    position: 'relative',
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loveButton: {
    position: 'absolute',
    top: 17,
    right: 42,
    width: 22,
    height: 22,
    zIndex: 60,
  },
  subsButton: {
    position: 'absolute',
    top: 16,
    left: 42,
    width: 26,
    height: 26,
    zIndex: 60,
  },
  nandezudButton: {
    position: 'absolute',
    top: 18,
    left: CONTENT_WIDTH / 2 - 68,
    width: 130,
    height: 23,
    zIndex: 60,
    
  },
  buttonImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  tryOnResultsContainer: {
    position: 'absolute',
    top: 70,
    left: 0,
    right: 0,
    zIndex: 56,
  },
  tryOnResultsContent: {
    paddingLeft: CONTENT_WIDTH * 0.25,
  },
  profileImage: {
    width: 200,
    height: 320,
    marginRight: 37,
    borderRadius: 10,
  },
  tryOnResultImage: {
    width: 210,
    height: 320,
    marginRight: 70,
    borderRadius: supportsClipPath ? 0 : 10,
    clipPath: supportsClipPath ? 'inset(0 round 10px)' : 'none',
    transform: [{ scaleX: 0.89 }],
  },
  errorText: {
    color: 'red',
    fontSize: 16,
    marginBottom: 10,
  },
  retryButton: {
    backgroundColor: '#007AFF',
    padding: 10,
    borderRadius: 5,
  },
  retryButtonText: {
    color: 'white',
    fontSize: 16,
  },
  deleteButton: {
    position: 'absolute',
    bottom: 12,
    right: 160,
    width: 112,
    backgroundColor: 'red',
    padding: 10,
    borderRadius: 20,
  },
  deleteButtonText: {
    color: 'white',
    fontSize: 18,
    fontWeight: '300',
    textAlign: 'center',
  },
  categoryButtonsContainer: {
    position: 'absolute',
    bottom: 18,
    left: 0,
    right: 0,
    height: CONTENT_HEIGHT,
    zIndex: 55,
  },
  categoryButton: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoryButtonImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  categoriesSection: {
    position: 'absolute',
    top: CONTENT_HEIGHT * 0.50,
    left: 47,
    right: 20,
    zIndex: 58,
  },
  allButton: {
    padding: 2,
    zIndex: 100,
    
  },
  categoriesText: {
    color: 'white',
    fontSize: 24,
    fontWeight: '300',
  },
  categoriesLine: {
    height: 1,
    backgroundColor: 'white',
    marginTop: 5,
    width: '90%',
  },
});

export default AllScreen;