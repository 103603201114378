import React from 'react';

const TermsAndConditions: React.FC = () => (
  <div>
    <h1>Terms and Conditions</h1>
    <p>Last updated: August 20, 2024</p>

    <h2>1. Introduction and Acceptance of Terms</h2>
    <p>Welcome to Nandezu Inc. ("Company", "we", "our", "us"). These Terms and Conditions ("Terms", "Terms and Conditions") govern your use of our website and mobile application (collectively, the "Service") operated by Nandezu Inc.</p>
    <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.</p>

    <h2>2. Definitions</h2>
    <p>For the purposes of these Terms and Conditions:</p>
    <ul>
      <li>"User", "You", and "Your" refers to you, the person accessing this Service and accepting these Terms and Conditions.</li>
      <li>"Content" refers to text, images, photos, audio, video, and all other forms of data or communication.</li>
      <li>"User Content" refers to any content that users submit, post, or transmit to, or using, the Service.</li>
      <li>"Nandezu Content" refers to all Content that Nandezu Inc. makes available through the Service.</li>
    </ul>

    <h2>3. Registration and Account</h2>
    <p>3.1. To access certain features of the Service, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
    <p>3.2. You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password. You agree not to disclose your password to any third party.</p>
    <p>3.3. You agree that you will not create more than one account, create an account for anyone other than yourself, or create an account using a false identity or information.</p>
    <p>3.4. We reserve the right to refuse registration of, or cancel, accounts which we deem inappropriate.</p>

    <h2>4. Use of Service</h2>
    <p>4.1. The Service is intended for your personal, non-commercial use.</p>
    <p>4.2. You agree not to use the Service:</p>
    <ul>
      <li>In any way that violates any applicable national or international law or regulation.</li>
      <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter," "spam," or any other similar solicitation.</li>
      <li>To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity.</li>
      <li>In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</li>
    </ul>
    <p>4.3. We reserve the right to terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>

    <h2>5. Virtual Try-On and Photo Upload</h2>
    <p>5.1. Our Service provides a virtual try-on feature that allows you to upload photos and virtually try on clothing and accessories.</p>
    <p>5.2. By uploading photos to our Service, you grant Nandezu Inc. a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the photos in connection with the Service and Nandezu Inc.'s (and its successors' and affiliates') business, including without limitation for promoting and redistributing part or all of the Service in any media formats and through any media channels.</p>
    <p>5.3. You represent and warrant that:</p>
    <ul>
      <li>You own the content you upload or have the right to use it and grant us the rights and license as provided in these Terms.</li>
      <li>The uploading and use of your content on or through the Service does not violate, misappropriate or infringe on the rights of any third party, including, without limitation, privacy rights, publicity rights, copyrights, trademark and/or other intellectual property rights.</li>
    </ul>
    <p>5.4. We reserve the right to remove any content from the Service at any time, without prior notice and at our sole discretion.</p>

    <h2>6. Data Usage and Third-Party Sharing</h2>
    <p>6.1. By using our Service, you explicitly consent to Nandezu Inc. collecting, analyzing, and sharing your shopping preferences, behavior data, and other non-personally identifiable information with third parties for business purposes. This includes, but is not limited to:</p>
    <ul>
      <li>Analyzing shopping trends and patterns</li>
      <li>Improving our Service and user experience</li>
      <li>Tailoring marketing and promotional offers</li>
      <li>Sharing aggregated data with business partners and advertisers</li>
      <li>Conducting market research and analytics</li>
      <li>Developing new products and services</li>
    </ul>
    <p>6.2. We ensure that any shared data is anonymized and does not contain personally identifiable information unless explicitly consented to by you.</p>
    <p>6.3. You acknowledge and agree that by providing any personal information through the Service, you grant Nandezu Inc. and its affiliates, service providers, and business partners a worldwide, perpetual, irrevocable, non-exclusive, royalty-free license to use such information for the purposes described in these Terms and our Privacy Policy.</p>
    <p>6.4. You may opt-out of certain data sharing practices through your account settings. However, opting out may limit your ability to use certain features of the Service.</p>

    <h2>7. Intellectual Property Rights</h2>
    <p>7.1. The Service and its original content (excluding User Content), features, and functionality are and will remain the exclusive property of Nandezu Inc. and its licensors.</p>
    <p>7.2. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Nandezu Inc.</p>
    <p>7.3. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service without the express written permission by us.</p>

    <h2>8. User Content</h2>
    <p>8.1. You retain any and all of your rights to any User Content you submit, post or display on or through the Service and you are responsible for protecting those rights.</p>
    <p>8.2. You agree that any User Content that you post may be viewed by other users and may be viewed by any person visiting or participating in the Service.</p>
    <p>8.3. Nandezu Inc. reserves the right to remove any User Content from the Service at any time without notice.</p>

    <h2>9. Privacy</h2>
    <p>9.1. Your use of the Service is also governed by our Privacy Policy, which is incorporated into these Terms and Conditions by reference.</p>
    <p>9.2. You understand that through your use of the Service, you consent to the collection and use of this information, including the transfer of this information to the United States and/or other countries for storage, processing, and use by Nandezu Inc. and its affiliates.</p>

    <h2>10. Limitations of Liability</h2>
    <p>10.1. In no event shall Nandezu Inc., nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:</p>
    <ul>
      <li>Your access to or use of or inability to access or use the Service;</li>
      <li>Any conduct or content of any third party on the Service;</li>
      <li>Any content obtained from the Service; and</li>
      <li>Unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</li>
    </ul>

    <h2>11. Disclaimer</h2>
    <p>11.1. Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>
    <p>11.2. Nandezu Inc., its subsidiaries, affiliates, and its licensors do not warrant that:</p>
    <ul>
      <li>The Service will function uninterrupted, secure or available at any particular time or location;</li>
      <li>Any errors or defects will be corrected;</li>
      <li>The Service is free of viruses or other harmful components;</li>
      <li>The results of using the Service will meet your requirements.</li>
    </ul>

    <h2>12. Governing Law</h2>
    <p>12.1. These Terms shall be governed and construed in accordance with the laws of California, United States, without regard to its conflict of law provisions.</p>
    <p>12.2. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.</p>

    <h2>13. Changes to Terms</h2>
    <p>13.1. We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect.</p>
    <p>13.2. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>

    <h2>14. Indemnification</h2>
    <p>14.1. You agree to defend, indemnify and hold harmless Nandezu Inc. and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of:</p>
    <ul>
      <li>Your use and access of the Service, by you or any person using your account and password;</li>
      <li>A breach of these Terms;</li>
      <li>Content posted on the Service;</li>
      <li>Your violation of any rights of a third party, including without limitation any copyright, property, or privacy right.</li>
    </ul>

    <h2>15. Termination</h2>
    <p>15.1. We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
    <p>15.2. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
    <p>15.3. Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>

    <h2>16. Contact Information</h2>
    <p>If you have any questions about these Terms, please contact us at:</p>
    <p>
      Nandezu Inc.<br />
      Address: Werqwise San Mateo, CA 94402<br />
      4 West 4th Ave San Francisco CA<br />
      Email: info@nandezu.com
    </p>

    <h2>17. Acknowledgement</h2>
    <p>BY USING THE SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</p>
  </div>
);

export default TermsAndConditions;