import React, { useCallback, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  ImageSourcePropType,
  Alert,
  Animated,
  Modal,
  TextInput,
  ScrollView,
  Dimensions,
} from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

// Import images

import nandezudImage from '../assets/images/nandezu.png';
import loveImage from '../assets/images/love.png';
import subsImage from '../assets/images/subs.png';

const BASE_URL = 'https://app-tdh1.onrender.com';

// Get screen dimensions
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');

// Adjustable parameters for button group
const BUTTON_GROUP_WIDTH = SCREEN_WIDTH * 0.8;
const BUTTON_GROUP_HEIGHT = SCREEN_HEIGHT * 0.6;
const BUTTON_GROUP_TOP = SCREEN_HEIGHT * 0.2;
const BUTTON_GROUP_LEFT = (SCREEN_WIDTH - BUTTON_GROUP_WIDTH) / 2;

const regions = [
  "Argentina", "Australia", "Austria", "Bangladesh", "Belgium", "Brazil", "Canada", "Chile", 
  "China", "Colombia", "Czech Republic", "Denmark", "Dominican Republic", "Ecuador", "Egypt", 
  "Finland", "France", "Germany", "Greece", "Hong Kong", "Hungary", "India", "Ireland", "Israel", 
  "Italy", "Japan", "Jordan", "Kenya", "Mexico", "Netherlands", "New Zealand", "Nigeria", "Norway", 
  "Peru", "Poland", "Portugal", "Qatar", "Russia", "Saudi Arabia", "Singapore", "Slovakia", "Slovenia", 
  "South Africa", "South Korea", "Spain", "Sweden", "Switzerland", "Taiwan", "Thailand", "Turkey", 
  "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Vietnam", "Bulgaria"
];

const MenuScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams<{ userId: string }>();
  const { token } = location.state as { token: string };

  const [error, setError] = useState<string | null>(null);
  const [screenFadeAnim] = useState(new Animated.Value(1));
  const [isChangeUsernameModalVisible, setChangeUsernameModalVisible] = useState(false);
  const [isChangePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
  const [isChangeEmailModalVisible, setChangeEmailModalVisible] = useState(false);
  const [isChangeRegionModalVisible, setChangeRegionModalVisible] = useState(false);
  const [isContactModalVisible, setContactModalVisible] = useState(false);
  const [isPrivacyPolicyModalVisible, setPrivacyPolicyModalVisible] = useState(false);
  const [isTermsConditionsModalVisible, setTermsConditionsModalVisible] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');

  const handleLogout = useCallback(async () => {
    try {
      if (!token) throw new Error('User not authenticated');

      await axios.post(`${BASE_URL}/user/logout/`, {}, {
        headers: { 'Authorization': `Token ${token}` }
      });

      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
      Alert.alert('Logout Failed', 'An error occurred during logout. Please try again.');
    }
  }, [token, navigate]);

  const handleButtonClick = useCallback((buttonName: string) => {
    if (!userId) {
      console.error('UserId is missing');
      return;
    }

    Animated.timing(screenFadeAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      switch (buttonName) {
        case 'Love':
          navigate(`/manage-profile-picture/${userId}`, { state: { token, userId } });
          break;
        case 'Subs':
          navigate(`/sub-screen/${userId}`, { state: { token, userId } });
          break;
        case 'Nandezud':
          navigate(`/user/${userId}`, { state: { token } });
          break;
      }
    });
  }, [navigate, token, userId, screenFadeAnim]);

  const handleChangeUsername = useCallback(() => {
    setChangeUsernameModalVisible(true);
  }, []);

  const handleChangePassword = useCallback(() => {
    setChangePasswordModalVisible(true);
  }, []);

  const handleChangeEmail = useCallback(() => {
    setChangeEmailModalVisible(true);
  }, []);

  const handleChangeRegion = useCallback(() => {
    setChangeRegionModalVisible(true);
  }, []);

  const handleContact = useCallback(() => {
    setContactModalVisible(true);
  }, []);

  const handlePrivacyPolicy = useCallback(() => {
    setPrivacyPolicyModalVisible(true);
  }, []);

  const handleTermsConditions = useCallback(() => {
    setTermsConditionsModalVisible(true);
  }, []);

  const handleUsernameChange = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/change_username/`,
        { newUserName: newUsername, password: currentPassword },
        { headers: { 'Authorization': `Token ${token}` } }
      );

      if (response.status === 200) {
        Alert.alert('Success', 'Username changed successfully');
        setChangeUsernameModalVisible(false);
        setNewUsername('');
        setCurrentPassword('');
      }
    } catch (error) {
      console.error('Username change failed:', error);
      Alert.alert('Error', 'Failed to change username. Please try again.');
    }
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmNewPassword) {
      Alert.alert('Error', 'New passwords do not match');
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/change_password/`,
        { currentPassword, newPassword, confirmNewPassword },
        { headers: { 'Authorization': `Token ${token}` } }
      );

      if (response.status === 200) {
        Alert.alert('Success', 'Password changed successfully');
        setChangePasswordModalVisible(false);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      }
    } catch (error) {
      console.error('Password change failed:', error);
      Alert.alert('Error', 'Failed to change password. Please try again.');
    }
  };

  const handleEmailChange = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/change_email/`,
        { newEmail, password: currentPassword },
        { headers: { 'Authorization': `Token ${token}` } }
      );

      if (response.status === 200) {
        Alert.alert('Success', 'Email changed successfully');
        setChangeEmailModalVisible(false);
        setNewEmail('');
        setCurrentPassword('');
      }
    } catch (error) {
      console.error('Email change failed:', error);
      Alert.alert('Error', 'Failed to change email. Please try again.');
    }
  };

  const handleRegionChange = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/change_region/`,
        { newRegion: selectedRegion },
        { headers: { 'Authorization': `Token ${token}` } }
      );

      if (response.status === 200) {
        Alert.alert('Success', 'Shopping region changed successfully');
        setChangeRegionModalVisible(false);
        setSelectedRegion('');
      }
    } catch (error) {
      console.error('Region change failed:', error);
      Alert.alert('Error', 'Failed to change shopping region. Please try again.');
    }
  };

  if (error) {
    return (
      <View style={styles.centered}>
        <Text style={styles.errorText}>{error}</Text>
        <TouchableOpacity style={styles.retryButton} onPress={() => setError(null)}>
          <Text style={styles.retryButtonText}>Retry</Text>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      
      <Animated.View style={[styles.content, { opacity: screenFadeAnim }]}>
        <TouchableOpacity style={styles.loveButton} onPress={() => handleButtonClick('Love')}>
          <Image source={loveImage as ImageSourcePropType} style={styles.buttonImage} />
        </TouchableOpacity>
        
        <TouchableOpacity style={styles.subsButton} onPress={() => handleButtonClick('Subs')}>
          <Image source={subsImage as ImageSourcePropType} style={styles.buttonImage} />
        </TouchableOpacity>
        
        <TouchableOpacity style={styles.nandezudButton} onPress={() => handleButtonClick('Nandezud')}>
          <Image source={nandezudImage as ImageSourcePropType} style={styles.buttonImage} />
        </TouchableOpacity>

        <View style={styles.buttonGroup}>
          <TouchableOpacity style={styles.changeButton} onPress={handleChangeUsername}>
            <Text style={styles.changeButtonText}>Change Username</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.changeButton} onPress={handleChangePassword}>
            <Text style={styles.changeButtonText}>Change Password</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.changeButton} onPress={handleChangeEmail}>
            <Text style={styles.changeButtonText}>Change Email</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.changeButton} onPress={handleChangeRegion}>
            <Text style={styles.changeButtonText}>Change Region</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.changeButton} onPress={handleContact}>
            <Text style={styles.changeButtonText}>Contact</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.changeButton} onPress={handlePrivacyPolicy}>
            <Text style={styles.changeButtonText}>Privacy Policy</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.changeButton} onPress={handleTermsConditions}>
            <Text style={styles.changeButtonText}>Terms & Conditions</Text>
          </TouchableOpacity>
        </View>

        <TouchableOpacity style={styles.logoutButton} onPress={handleLogout}>
          <Text style={styles.buttonText}>Logout</Text>
        </TouchableOpacity>
      </Animated.View>

      {/* Modals remain unchanged */}
      {/* Change Username Modal */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={isChangeUsernameModalVisible}
        onRequestClose={() => setChangeUsernameModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Change Username</Text>
            <TextInput
              style={styles.input}
              placeholder="New Username"
              value={newUsername}
              onChangeText={setNewUsername}
            />
            <TextInput
              style={styles.input}
              placeholder="Current Password"
              secureTextEntry
              value={currentPassword}
              onChangeText={setCurrentPassword}
            />
            <TouchableOpacity style={styles.submitButton} onPress={handleUsernameChange}>
              <Text style={styles.submitButtonText}>Submit</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.cancelButton} onPress={() => setChangeUsernameModalVisible(false)}>
              <Text style={styles.cancelButtonText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      {/* Change Password Modal */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={isChangePasswordModalVisible}
        onRequestClose={() => setChangePasswordModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Change Password</Text>
            <TextInput
              style={styles.input}
              placeholder="Current Password"
              secureTextEntry
              value={currentPassword}
              onChangeText={setCurrentPassword}
            />
            <TextInput
              style={styles.input}
              placeholder="New Password"
              secureTextEntry
              value={newPassword}
              onChangeText={setNewPassword}
            />
            <TextInput
              style={styles.input}
              placeholder="Confirm New Password"
              secureTextEntry
              value={confirmNewPassword}
              onChangeText={setConfirmNewPassword}
            />
            <TouchableOpacity style={styles.submitButton} onPress={handlePasswordChange}>
              <Text style={styles.submitButtonText}>Submit</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.cancelButton} onPress={() => setChangePasswordModalVisible(false)}>
              <Text style={styles.cancelButtonText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      {/* Change Email Modal */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={isChangeEmailModalVisible}
        onRequestClose={() => setChangeEmailModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Change Email</Text>
            <TextInput
              style={styles.input}
              placeholder="New Email"
              value={newEmail}
              onChangeText={setNewEmail}
              keyboardType="email-address"
            />
            <TextInput
              style={styles.input}
              placeholder="Current Password"
              secureTextEntry
              value={currentPassword}
              onChangeText={setCurrentPassword}
            />
            <TouchableOpacity style={styles.submitButton} onPress={handleEmailChange}>
              <Text style={styles.submitButtonText}>Submit</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.cancelButton} onPress={() => setChangeEmailModalVisible(false)}>
              <Text style={styles.cancelButtonText}>Cancel</Text>
              </TouchableOpacity>
          </View>
        </View>
      </Modal>

      {/* Change Region Modal */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={isChangeRegionModalVisible}
        onRequestClose={() => setChangeRegionModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Change Shopping Region</Text>
            <ScrollView style={styles.pickerContainer}>
              <Picker
                selectedValue={selectedRegion}
                onValueChange={(itemValue) => setSelectedRegion(itemValue)}
              >
                <Picker.Item label="Select shopping region" value="" />
                {regions.map((region) => (
                  <Picker.Item key={region} label={region} value={region} />
                ))}
              </Picker>
            </ScrollView>
            <TouchableOpacity style={styles.submitButton} onPress={handleRegionChange}>
              <Text style={styles.submitButtonText}>Submit</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.cancelButton} onPress={() => setChangeRegionModalVisible(false)}>
              <Text style={styles.cancelButtonText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      {/* Contact Modal */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={isContactModalVisible}
        onRequestClose={() => setContactModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Contact Us</Text>
            <Text style={styles.modalText}>
              Please contact us at: info@nandezu.com
            </Text>
            <TouchableOpacity style={styles.closeButton} onPress={() => setContactModalVisible(false)}>
              <Text style={styles.closeButtonText}>Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      {/* Privacy Policy Modal */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={isPrivacyPolicyModalVisible}
        onRequestClose={() => setPrivacyPolicyModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <ScrollView style={styles.modalContent}>
            <Text style={styles.modalTitle}>Privacy Policy</Text>
            <Text style={styles.modalText}>
              1. Information Collection: We collect personal information that you provide directly to us, such as when you create an account, make a purchase, or contact us for support.

              2. Use of Information: We use the information we collect to provide, maintain, and improve our services, to process your transactions, and to communicate with you.

              3. Information Sharing: We do not sell your personal information. We may share your information with service providers who perform services on our behalf, or when required by law.

              4. Data Security: We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing and against accidental loss, destruction, or damage.

              5. Your Rights: You have the right to access, correct, or delete your personal information. You may also have the right to restrict or object to certain processing of your data.

              6. Changes to This Policy: We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.

              7. Contact Us: If you have any questions about this privacy policy, please contact us at info@nandezu.com.
            </Text>
            <TouchableOpacity style={styles.closeButton} onPress={() => setPrivacyPolicyModalVisible(false)}>
              <Text style={styles.closeButtonText}>Close</Text>
            </TouchableOpacity>
          </ScrollView>
        </View>
      </Modal>

      {/* Terms and Conditions Modal */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={isTermsConditionsModalVisible}
        onRequestClose={() => setTermsConditionsModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <ScrollView style={styles.modalContent}>
            <Text style={styles.modalTitle}>Terms and Conditions</Text>
            <Text style={styles.modalText}>
              1. Acceptance of Terms: By accessing or using our service, you agree to be bound by these Terms and Conditions.

              2. User Accounts: You are responsible for safeguarding the password that you use to access the service and for any activities or actions under your password.

              3. Content: Our service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material. You are responsible for the content that you post to the service.

              4. Prohibited Uses: You may not use our service for any illegal or unauthorized purpose nor may you, in the use of the service, violate any laws in your jurisdiction.

              5. Intellectual Property: The service and its original content, features and functionality are and will remain the exclusive property of Nandezu and its licensors.

              6. Termination: We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.

              7. Limitation of Liability: In no event shall Nandezu, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages.

              8. Changes: We reserve the right, at our sole discretion, to modify or replace these Terms at any time.

              9. Contact Us: If you have any questions about these Terms, please contact us at info@nandezu.com.
            </Text>
            <TouchableOpacity style={styles.closeButton} onPress={() => setTermsConditionsModalVisible(false)}>
              <Text style={styles.closeButtonText}>Close</Text>
            </TouchableOpacity>
          </ScrollView>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loveButton: {
    position: 'absolute',
    top: SCREEN_HEIGHT * 0.037,
    right: SCREEN_WIDTH * 0.07,
    width: SCREEN_WIDTH * 0.060,
    height: SCREEN_WIDTH * 0.060,
    zIndex: 10,
  },
  subsButton: {
    position: 'absolute',
    top: SCREEN_HEIGHT * 0.035,
    left: SCREEN_WIDTH * 0.07,
    width: SCREEN_WIDTH * 0.07,
    height: SCREEN_WIDTH * 0.07,
    zIndex: 10,
  },
  nandezudButton: {
    position: 'absolute',
    top: SCREEN_HEIGHT * 0.03,
    left: (SCREEN_WIDTH - SCREEN_WIDTH * 0.27) / 2,
    width: SCREEN_WIDTH * 0.28,
    height: SCREEN_HEIGHT * 0.05,
    zIndex: 10,
  },
  buttonImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  buttonGroup: {
    position: 'absolute',
    top: BUTTON_GROUP_TOP,
    left: BUTTON_GROUP_LEFT,
    width: BUTTON_GROUP_WIDTH,
    height: BUTTON_GROUP_HEIGHT,
    justifyContent: 'space-between',
  },
  changeButton: {
    backgroundColor: 'transparent',
    padding: SCREEN_HEIGHT * 0.015,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(255, 255, 255, 0.3)',
  },
  changeButtonText: {
    color: 'white',
    fontSize: SCREEN_HEIGHT * 0.025,
    fontWeight: '300',
  },
  logoutButton: {
    position: 'absolute',
    bottom: SCREEN_HEIGHT * 0.12,
    backgroundColor: '#f44336',
    padding: SCREEN_HEIGHT * 0.012,
    borderRadius: SCREEN_HEIGHT * 0.03,
    width: SCREEN_WIDTH * 0.4,
    alignItems: 'center',
  },
  buttonText: {
    color: 'white',
    fontSize: SCREEN_HEIGHT * 0.025,
    fontWeight: '300',
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    color: 'red',
    fontSize: SCREEN_HEIGHT * 0.025,
    marginBottom: SCREEN_HEIGHT * 0.02,
  },
  retryButton: {
    backgroundColor: '#007AFF',
    padding: SCREEN_HEIGHT * 0.015,
    borderRadius: SCREEN_HEIGHT * 0.01,
  },
  retryButtonText: {
    color: 'white',
    fontSize: SCREEN_HEIGHT * 0.025,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: SCREEN_HEIGHT * 0.03,
    borderRadius: SCREEN_HEIGHT * 0.02,
    width: SCREEN_WIDTH * 0.8,
    maxHeight: SCREEN_HEIGHT * 0.8,
  },
  modalTitle: {
    fontSize: SCREEN_HEIGHT * 0.03,
    fontWeight: 'bold',
    marginBottom: SCREEN_HEIGHT * 0.02,
    textAlign: 'center',
  },
  modalText: {
    fontSize: SCREEN_HEIGHT * 0.02,
    marginBottom: SCREEN_HEIGHT * 0.02,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: SCREEN_HEIGHT * 0.01,
    padding: SCREEN_HEIGHT * 0.015,
    marginBottom: SCREEN_HEIGHT * 0.015,
  },
  submitButton: {
    backgroundColor: '#4CAF50',
    padding: SCREEN_HEIGHT * 0.015,
    borderRadius: SCREEN_HEIGHT * 0.01,
    alignItems: 'center',
    marginTop: SCREEN_HEIGHT * 0.015,
  },
  submitButtonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: SCREEN_HEIGHT * 0.02,
  },
  cancelButton: {
    backgroundColor: '#f44336',
    padding: SCREEN_HEIGHT * 0.015,
    borderRadius: SCREEN_HEIGHT * 0.01,
    alignItems: 'center',
    marginTop: SCREEN_HEIGHT * 0.015,
  },
  cancelButtonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: SCREEN_HEIGHT * 0.02,
  },
  closeButton: {
    backgroundColor: '#2196F3',
    padding: SCREEN_HEIGHT * 0.015,
    borderRadius: SCREEN_HEIGHT * 0.01,
    alignItems: 'center',
    marginTop: SCREEN_HEIGHT * 0.015,
  },
  closeButtonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: SCREEN_HEIGHT * 0.02,
  },
  pickerContainer: {
    maxHeight: SCREEN_HEIGHT * 0.3,
    marginBottom: SCREEN_HEIGHT * 0.015,
  },
});

export default MenuScreen;