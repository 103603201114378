import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import backgroundImage from '../assets/images/backround.png';

const ScrollContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
`;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 20px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  color: #fff;
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 30px;
  text-align: center;
`;

const TextContainer = styled.div`
  color: #fff;
  font-size: 16px;
  text-align: left;
  max-width: 80%;
  margin: 0 auto 40px;
  line-height: 1.6;
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Button = styled.button`
  width: 180px;
  height: 45px;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 22px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 14px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
    transform: scale(1.05);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.7);
    transform: scale(0.95);
  }
`;

const GuideScreen5: React.FC = () => {
  const navigate = useNavigate();

  const [text, setText] = useState('');
  const fullText = `Hello! Welcome to Nandezu! Your personal AI fashion assistant is here to revolutionize your style journey.

Enjoy virtual try-ons, AI-powered recommendations, and the latest fashion trends from over 400 brands and 15.000 products.

Your account setup is complete. You can now start exploring the world of fashion with Nandezu. Please press confirm and Login.`;

  useEffect(() => {
    let index = 0;
    const timer = setInterval(() => {
      setText(fullText.slice(0, index));
      index++;
      if (index > fullText.length) {
        clearInterval(timer);
      }
    }, 50);

    return () => clearInterval(timer);
  }, [fullText]);

  const handleConfirm = () => {
    navigate('/login');
  };

  return (
    <ScrollContainer>
      <Container>
        <Title>NANDEZU</Title>
        <TextContainer>{text}</TextContainer>
      </Container>
      <ButtonContainer>
        <Button onClick={handleConfirm}>Confirm</Button>
      </ButtonContainer>
    </ScrollContainer>
  );
};

export default GuideScreen5;