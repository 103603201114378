import React, { useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { BASE_URL } from '../config';

const SCREEN_WIDTH = window.innerWidth;
const CONTAINER_WIDTH = SCREEN_WIDTH * 0.6;
const CONTAINER_HEIGHT = CONTAINER_WIDTH * 1.5;

const Container = styled.div`
  min-height: 100vh;
  background-color: #020209;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.h1`
  color: #fff;
  font-size: 30px;
  font-weight: 300;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const ImageContainer = styled.div<{ isPreview: boolean }>`
  width: ${props => props.isPreview ? `${CONTAINER_WIDTH}px` : '70vw'};
  height: ${props => props.isPreview ? `${CONTAINER_HEIGHT}px` : '60vh'};
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Button = styled.button<{ disabled?: boolean }>`
  width: 180px;
  height: 45px;
  margin: 8px 0;
  padding: 10px 20px;
  background-color: ${props => props.disabled ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.3)'};
  color: white;
  border: none;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  border-radius: 22px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 14px;

  &:hover {
    background-color: ${props => props.disabled ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.5)'};
    transform: ${props => props.disabled ? 'none' : 'scale(1.05)'};
  }

  &:active {
    background-color: ${props => props.disabled ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.7)'};
    transform: ${props => props.disabled ? 'none' : 'scale(0.95)'};
  }
`;

const TextContainer = styled.div`
  text-align: center;
  color: white;
  margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const CustomReactCrop = styled(ReactCrop)`
  & > div {
    border: 2px solid rgba(255, 255, 255, 0.8) !important;
    border-radius: 10px;
  }
`;

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  padding: 20px 0;
`;

const ErrorMessage = styled.p`
  color: #ff6b6b;
  margin-top: 10px;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
`;

const UploadPhoto: React.FC = () => {
  const [src, setSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 30,
    height: 45,
    x: 35,
    y: 27.5,
  });
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isCropping, setIsCropping] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = (location.state as { userId?: string }) || {};

  const inputRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const resetState = () => {
    setSrc(null);
    setCroppedImageUrl(null);
    setIsPreviewMode(false);
    setIsProcessing(false);
    setIsCropping(false);
    setError(null);
    setCrop({
      unit: '%',
      width: 30,
      height: 45,
      x: 35,
      y: 27.5,
    });
  };

  const loadImage = (file: File) => {
    setIsProcessing(true);
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const result = event.target?.result;
      if (result && typeof result === 'string') {
        const img = new Image();
        img.onload = () => {
          const aspect = img.height / img.width;
          setCrop({
            unit: '%',
            width: 30,
            height: 30 * aspect,
            x: 35,
            y: (100 - 30 * aspect) / 2,
          });
          setSrc(result);
          setIsProcessing(false);
        };
        img.src = result;
      }
    };
    reader.readAsDataURL(file);
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      resetState();
      loadImage(e.target.files[0]);
    }
  };

  const getCroppedImg = useCallback(async (image: HTMLImageElement, crop: PixelCrop) => {
    if (!crop.width || !crop.height) return '';

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
    }

    return new Promise<string>((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          resolve('');
          return;
        }
        resolve(URL.createObjectURL(blob));
      }, 'image/jpeg', 1);
    });
  }, []);

  const makeClientCrop = useCallback(async (crop: PixelCrop) => {
    if (src && crop.width && crop.height) {
      const image = imageRef.current;
      if (image) {
        const croppedImageUrl = await getCroppedImg(image, crop);
        setCroppedImageUrl(croppedImageUrl);
      }
    }
  }, [src, getCroppedImg]);

  const onCropComplete = (crop: PixelCrop) => {
    makeClientCrop(crop);
  };

  const handlePreview = async () => {
    setIsCropping(true);
    await makeClientCrop(crop as PixelCrop);
    setIsPreviewMode(true);
    setIsCropping(false);
  };

  const handleBack = () => {
    setIsPreviewMode(false);
  };

  const handleRepeat = () => {
    resetState();
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const uploadPhoto = async () => {
    if (!croppedImageUrl || !userId) return;

    setIsUploading(true);
    setError(null);

    try {
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();
      const formData = new FormData();
      formData.append('profile_image', blob, 'photo.jpg');

      const token = localStorage.getItem('userToken');
      if (!token) throw new Error('No token found');

      const uploadResponse = await axios.post(
        `${BASE_URL}/user/users/${userId}/upload_profile_image/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Token ${token}`,
          },
        }
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        navigate('/login', { state: { userId } });
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      console.error('Error uploading photo:', error);
      setError('Failed to upload photo. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Container>
      <Title>NANDEZU</Title>
      <CenteredContent>
        {!src && (
          <>
            <TextContainer>
              <h2>Select a photo to upload</h2>
              <p>Add a high-quality, well-lit photograph, preferably a full-body shot where the person is alone in the photo.</p>
            </TextContainer>
            <Button onClick={() => inputRef.current?.click()} disabled={isProcessing || isUploading}>
              Select Photo
            </Button>
            {isProcessing && <LoadingOverlay>Processing image...</LoadingOverlay>}
          </>
        )}
        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          style={{ display: 'none' }}
        />

        {src && !isPreviewMode && (
          <>
            <ImageContainer isPreview={false}>
              <CustomReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={onCropComplete}
                aspect={2 / 3}
                style={{
                  margin: 'auto',
                  maxWidth: '80vw',
                  maxHeight: '60vh',
                }}
              >
                <img 
                  ref={imageRef}
                  src={src} 
                  alt="Upload" 
                  style={{ width: '100%', height: '100%' }} 
                />
              </CustomReactCrop>
            </ImageContainer>
            <Button onClick={handlePreview} disabled={isUploading || isCropping}>
              {isCropping ? 'Processing...' : 'Preview'}
            </Button>
          </>
        )}

        {isPreviewMode && croppedImageUrl && (
          <>
            <ImageContainer isPreview={true}>
              <PreviewImage 
                src={croppedImageUrl} 
                alt="Preview" 
              />
            </ImageContainer>
            <ButtonContainer>
              <Button onClick={uploadPhoto} disabled={isUploading}>
                {isUploading ? 'Uploading...' : 'Confirm'}
              </Button>
              <Button onClick={handleRepeat} disabled={isUploading}>
                Repeat
              </Button>
              <Button onClick={handleBack} disabled={isUploading}>
                Back
              </Button>
            </ButtonContainer>
          </>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </CenteredContent>
    </Container>
  );
};

export default UploadPhoto;