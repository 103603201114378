import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../config';
import squareImage from '../assets/images/square.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #020209;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
`;

const SquareImage = styled.img`
  width: 280px;
  height: auto;
  margin-top: 50px;
  margin-bottom: 20px;
`;

const AppName = styled.h1`
  margin-top: 20px;
  color: #fff;
  font-size: 30px;
  font-weight: 300;
`;

const Subtitle = styled.p`
  margin-top: 20px;
  margin-bottom: 50px;
  color: #fff;
  font-size: 20px;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 140px;
`;

const PickerContainer = styled.div`
  width: 80%;
  margin-bottom: 20px;
`;

const PickerLabel = styled.label`
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px;
  display: block;
`;

const Select = styled.select`
  width: 100%;
  height: 50px;
  background-color: transparent;
  color: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  padding: 0 20px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;

  &:focus {
    outline: none;
  }

  option {
    background-color: #020209;
    color: #fff;
  }
` as React.FC<JSX.IntrinsicElements['select']>;

const ConfirmButton = styled.button`
  width: 180px;
  height: 45px;
  border: 1px solid #fff;
  border-radius: 25px;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
`;

const Message = styled.p`
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
  width: 80%;
`;

interface LocationState {
  nickname: string;
  email: string;
  password: string;
}

export default function AfterRegistration() {
  const location = useLocation();
  const navigate = useNavigate();
  const { nickname, email, password } = location.state as LocationState;
  const [shoppingRegion, setShoppingRegion] = useState('');
  const [gender, setGender] = useState('');
  const [message, setMessage] = useState('');

  const handleConfirm = async () => {
    if (!shoppingRegion || !gender) {
      setMessage('Please select a shopping region and gender.');
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/user/register/`, {
        username: nickname,
        email,
        password,
        shopping_region: shoppingRegion,
        gender
      });

      if (response.status === 201) {
        const { user_id, token } = response.data;
        localStorage.setItem('userToken', token);
        localStorage.setItem('userId', user_id.toString());
        navigate('/upload-photo', { state: { userId: user_id } });
      }
    } catch (error) {
      setMessage('Registration failed. Please try again.');
      console.error('Registration error:', error);
    }
  };

  return (
    <Container>
      <ContentWrapper>
        <SquareImage src={squareImage} alt="Square" />
        <AppName>NANDEZU</AppName>
        <Subtitle>Complete your profile</Subtitle>

        <ContentContainer>
          <PickerContainer>
            <PickerLabel>Shopping region</PickerLabel>
            <Select value={shoppingRegion} onChange={(e) => setShoppingRegion(e.target.value)}>
              <option value="">Select shopping region</option>
              <option value="Argentina">Argentina</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Belgium">Belgium</option>
              <option value="Brazil">Brazil</option>
              <option value="Canada">Canada</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Colombia">Colombia</option>
              <option value="Czech Republic">Czech Republic</option>
              <option value="Denmark">Denmark</option>
              <option value="Dominican Republic">Dominican Republic</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="Germany">Germany</option>
              <option value="Greece">Greece</option>
              <option value="Hong Kong">Hong Kong</option>
              <option value="Hungary">Hungary</option>
              <option value="India">India</option>
              <option value="Ireland">Ireland</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Japan">Japan</option>
              <option value="Jordan">Jordan</option>
              <option value="Kenya">Kenya</option>
              <option value="Mexico">Mexico</option>
              <option value="Netherlands">Netherlands</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Norway">Norway</option>
              <option value="Peru">Peru</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Qatar">Qatar</option>
              <option value="Russia">Russia</option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="South Africa">South Africa</option>
              <option value="South Korea">South Korea</option>
              <option value="Spain">Spain</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Taiwan">Taiwan</option>
              <option value="Thailand">Thailand</option>
              <option value="Turkey">Turkey</option>
              <option value="Uganda">Uganda</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States">United States</option>
              <option value="Vietnam">Vietnam</option>
              <option value="Bulgaria">Bulgaria</option>
            </Select>
          </PickerContainer>

          <PickerContainer>
            <PickerLabel>Gender</PickerLabel>
            <Select value={gender} onChange={(e) => setGender(e.target.value)}>
              <option value="">Select Gender</option>
              <option value="female">Female</option>
            </Select>
          </PickerContainer>

          {message && <Message>{message}</Message>}

          <ConfirmButton onClick={handleConfirm}>Confirm</ConfirmButton>
        </ContentContainer>
      </ContentWrapper>
    </Container>
  );
}