import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  ImageSourcePropType,
  Alert,
  Animated,
  Platform,
  Easing,
  Dimensions,
  ActivityIndicator,
  Linking,
  VirtualizedList,
  
  NativeSyntheticEvent,
  NativeScrollEvent
} from 'react-native';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSubscription } from '../contexts/SubscriptionContext';
import Lottie from 'lottie-react';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Import obrázků a animací

import nandezudImage from '../assets/images/nandezu.png';
import loveImage from '../assets/images/love.png';
import subsImage from '../assets/images/subs.png';
import bakcetImage from '../assets/images/Bakcet.png';
import tryonImage from '../assets/images/tryon.png';
import ufoAnimation from '../assets/ufo.json';

const BASE_URL = 'https://app-tdh1.onrender.com';

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');

// Definice pevných rozměrů obsahu
const CONTENT_WIDTH = 420;
const CONTENT_HEIGHT = 812;

const supportsClipPath = CSS.supports("clip-path", "inset(0 round 10px)");

const PRODUCT_LAYOUT = {
  WRAPPER: {
    width: SCREEN_WIDTH * 0.75,
    height: SCREEN_HEIGHT * 0.45,
    marginRight: SCREEN_WIDTH * -0.20,
  },
  BAKCET: {
    width: SCREEN_WIDTH * 0.75,
    height: '77%',
    top: '0%',
    zIndex: 1,
  },
  IMAGE: {
    width: SCREEN_WIDTH * 0.5,
    height: '75%',
    top: '2%',
    zIndex: 2,
  },
  MANUFACTURER: {
    fontSize: 19,
    top: '48%',
    zIndex: 3,
  },
  PRICE: {
    fontSize: 17,
    top: '55%',
    zIndex: 3,
  },
  TRYON_BUTTON: {
    width: SCREEN_WIDTH * 0.2,
    height: SCREEN_WIDTH * 0.1,
    top: '77%',
    left: '50%',
    transform: [{ translateX: -SCREEN_WIDTH * 0.1 }],
    zIndex: 4,
  },
};

interface User {
  id: string;
  username: string;
  email: string;
  subscription_type: string;
  profile_images: string[];
}

interface TryOnResult {
  id: string;
  result_image: string;
  created_at: string;
}

interface Product {
  id: string;
  name: string;
  price: string;
  image_url: string;
  manufacturer_name: string;
  store_link: string;
}

const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const ITEMS_PER_PAGE = 20;

const DressScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams<{ userId: string }>();
  const { token } = location.state as { token: string };

  const { updateSubscriptionLimits, virtualTryOnsRemaining } = useSubscription();

  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [tryOnResults, setTryOnResults] = useState<TryOnResult[]>([]);
  const [sortedTryOnResults, setSortedTryOnResults] = useState<TryOnResult[]>([]);
  const [enlargedImage, setEnlargedImage] = useState<TryOnResult | null>(null);
  const [isEnlarged, setIsEnlarged] = useState(false);
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [visibleProducts, setVisibleProducts] = useState<Product[]>([]);
  const [isTryingOn, setIsTryingOn] = useState(false);
  const [favorites, setFavorites] = useState<Set<string>>(new Set());
  const [showNoTryOnsMessage, setShowNoTryOnsMessage] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const enlargeAnimation = useRef(new Animated.Value(0)).current;
  const fadeAnimation = useRef(new Animated.Value(1)).current;
  const screenFadeAnim = useRef(new Animated.Value(1)).current;
  const containerHeightAnimation = useRef(new Animated.Value(400)).current;
  const hideShowAnimation = useRef(new Animated.Value(1)).current;
  const productListAnimation = useRef(new Animated.Value(1)).current;
  const noTryOnsMessageAnimation = useRef(new Animated.Value(0)).current;
  const dressButtonAnimation = useRef(new Animated.Value(1)).current;

  const scrollPositionRef = useRef(0);
  const productListRef = useRef<VirtualizedList<Product>>(null);

  const fetchUserData = useCallback(async () => {
    try {
      if (!token || !userId) throw new Error('User not authenticated');

      const response = await axios.get(`${BASE_URL}/users/${userId}/`, {
        headers: { 'Authorization': `Token ${token}` }
      });

      if (response.data) {
        setUser(response.data);
      } else {
        throw new Error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to load user data.');
    }
  }, [token, userId]);

  const fetchTryOnResults = useCallback(async () => {
    try {
      if (!token) throw new Error('User not authenticated');

      const response = await axios.get(`${BASE_URL}/tryon/results/`, {
        headers: { 'Authorization': `Token ${token}` }
      });
     
      setTryOnResults(response.data);
    } catch (error) {
      console.error('Error fetching try-on results:', error);
    }
  }, [token]);

  const fetchSubscriptionDetails = useCallback(async () => {
    try {
      if (!token) throw new Error('User not authenticated');

      const response = await axios.get(`${BASE_URL}/subscription/details/`, {
        headers: { 'Authorization': `Token ${token}` }
      });

      updateSubscriptionLimits(response.data);
    } catch (error) {
      console.error('Error fetching subscription details:', error);
    }
  }, [token, updateSubscriptionLimits]);

  const fetchProducts = useCallback(async () => {
    const currentTime = Date.now();
    const fiveMinutes = 30 * 60 * 1000;
    const category = 'dress';  // Toto je specifické pro JacketScreen

    try {
      const storedProducts = await AsyncStorage.getItem(`shuffledProducts_${category}`);
      const storedTime = await AsyncStorage.getItem(`lastUpdateTime_${category}`);

      if (storedProducts && storedTime) {
        const parsedTime = parseInt(storedTime, 10);

        if (currentTime - parsedTime < fiveMinutes) {
          console.log(`Méně než 5 minut od poslední aktualizace, používám existující produkty pro ${category}`);
          setAllProducts(JSON.parse(storedProducts));
          setVisibleProducts(JSON.parse(storedProducts).slice(0, ITEMS_PER_PAGE));
          return;
        }
      }

      if (!token) {
        throw new Error('User not authenticated');
      }

      const response = await axios.get(`${BASE_URL}/products/`, {
        headers: { 'Authorization': `Token ${token}` },
        params: { 
          clothing_category: category,
          seed: `${userId}-${currentTime}` 
        }
      });

      const shuffledProducts = shuffleArray([...response.data]);
      setAllProducts(shuffledProducts);
      setVisibleProducts(shuffledProducts.slice(0, ITEMS_PER_PAGE));
      
      await AsyncStorage.setItem(`shuffledProducts_${category}`, JSON.stringify(shuffledProducts));
      await AsyncStorage.setItem(`lastUpdateTime_${category}`, currentTime.toString());
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }, [token, userId]);

  const loadMoreProducts = useCallback(() => {
    if (isLoadingMore || visibleProducts.length >= allProducts.length) return;

    setIsLoadingMore(true);
    const nextProducts = allProducts.slice(visibleProducts.length, visibleProducts.length + ITEMS_PER_PAGE);
    setVisibleProducts(prev => [...prev, ...nextProducts]);
    setIsLoadingMore(false);
  }, [isLoadingMore, visibleProducts, allProducts]);

  const checkFavoriteStatus = useCallback(async (productId: string) => {
    try {
      if (!token) throw new Error('User not authenticated');

      const response = await axios.get(`${BASE_URL}/favorites/check/`, {
        params: { product: productId, user: userId },
        headers: { 'Authorization': `Token ${token}` }
      });

      return response.data.is_favorite;
    } catch (error) {
      console.error('Error checking favorite status:', error);
      return false;
    }
  }, [token, userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchUserData(), fetchTryOnResults(), fetchSubscriptionDetails()]);
        await fetchProducts();
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data.');
      }
    };

    fetchData();
  }, [fetchUserData, fetchTryOnResults, fetchSubscriptionDetails, fetchProducts]);

  useEffect(() => {
    const initializeFavorites = async () => {
      if (allProducts.length > 0) {
        const favoriteStatuses = await Promise.all(allProducts.map(product => checkFavoriteStatus(product.id)));
        const newFavorites = new Set(allProducts.filter((_, index) => favoriteStatuses[index]).map(product => product.id));
        setFavorites(newFavorites);
      }
    };

    initializeFavorites();
  }, [allProducts, checkFavoriteStatus]);

  useEffect(() => {
    const sorted = [...tryOnResults].sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
    setSortedTryOnResults(sorted);
  }, [tryOnResults]);

  useLayoutEffect(() => {
    if (productListRef.current && !isTryingOn) {
      setTimeout(() => {
        if (productListRef.current) {
          productListRef.current.scrollToOffset({
            offset: scrollPositionRef.current,
            animated: false
          });
        }
      }, 0);
    }
  }, [isTryingOn, tryOnResults]);

  const handleScroll = useCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
    scrollPositionRef.current = event.nativeEvent.contentOffset.x;
  }, []);

  const handleButtonClick = useCallback((buttonName: string) => {
    if (!userId) {
      console.error('UserId is missing');
      return;
    }

    const buttonAnimation = buttonName === 'Dress' ? dressButtonAnimation : screenFadeAnim;

    Animated.sequence([
      Animated.timing(buttonAnimation, {
        toValue: 0.8,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(buttonAnimation, {
        toValue: 1,
        duration: 100,
        useNativeDriver: true,
      }),
    ]).start(() => {
      switch (buttonName) {
        case 'Love':
          navigate(`/manage-profile-picture/${userId}`, { state: { token, userId } });
          break;
        case 'Subs':
          navigate(`/menu-screen/${userId}`, { state: { token, userId } });
          break;
        case 'Nandezud':
          navigate(`/user/${userId}`, { state: { token } });
          break;
        case 'Dress':
          navigate(`/all-screen/${userId}`, { state: { token } });
          break;
      }
    });
  }, [navigate, token, userId, screenFadeAnim, dressButtonAnimation]);

  const animateReturnToOriginalState = useCallback(() => {
    setIsEnlarged(false);
    setEnlargedImage(null);

    Animated.parallel([
      Animated.timing(enlargeAnimation, {
        toValue: 0,
        duration: 600,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.cubic)
      }),
      Animated.timing(fadeAnimation, {
        toValue: 1,
        duration: 600,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.cubic)
      }),
      Animated.timing(containerHeightAnimation, {
        toValue: 400,
        duration: 600,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.cubic)
      }),
      Animated.timing(hideShowAnimation, {
        toValue: 1,
        duration: 600,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.cubic)
      }),
      Animated.timing(productListAnimation, {
        toValue: 1,
        duration: 600,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.cubic)
      })
    ]).start();
  }, [enlargeAnimation, fadeAnimation, containerHeightAnimation, hideShowAnimation, productListAnimation]);

  const handleTryOnImageClick = useCallback((result: TryOnResult) => {
    if (enlargedImage && enlargedImage.id === result.id) {
      animateReturnToOriginalState();
    } else {
      setEnlargedImage(result);
      setIsEnlarged(true);
      Animated.parallel([
        Animated.timing(enlargeAnimation, {
          toValue: 1,
          duration: 600,
          useNativeDriver: false,
          easing: Easing.inOut(Easing.cubic)
        }),
        Animated.timing(fadeAnimation, {
          toValue: 0,
          duration: 600,
          useNativeDriver: false,
          easing: Easing.inOut(Easing.cubic)
        }),
        Animated.timing(containerHeightAnimation, {
          toValue: 600,
          duration: 600,
          useNativeDriver: false,
          easing: Easing.inOut(Easing.cubic)
        }),
        Animated.timing(hideShowAnimation, {
          toValue: 0,
          duration: 600,
          useNativeDriver: false,
          easing: Easing.inOut(Easing.cubic)
        }),
        Animated.timing(productListAnimation, {
          toValue: 0,
          duration: 600,
          useNativeDriver: false,
          easing: Easing.inOut(Easing.cubic)
        })
      ]).start();
    }
  }, [enlargedImage, enlargeAnimation, fadeAnimation, containerHeightAnimation, hideShowAnimation, productListAnimation, animateReturnToOriginalState]);

  const handleDeleteImage = useCallback(async (resultId: string) => {
    try {
      if (!token) {
        throw new Error('User not authenticated');
      }

      const isConfirmed = await new Promise<boolean>((resolve) => {
        if (Platform.OS === 'web') {
          resolve(window.confirm("Are you sure you want to delete this image?"));
        } else {
          Alert.alert(
            "Confirmation",
            "Are you sure you want to delete this image?",
            [
              { text: "No", onPress: () => resolve(false), style: "cancel" },
              { text: "Yes", onPress: () => resolve(true) }
            ]
          );
        }
      });

      if (isConfirmed) {
        const response = await axios.delete(`${BASE_URL}/tryon/delete-result/${resultId}/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        if (response.status === 204 || response.status === 200) {
          setTryOnResults(prev => prev.filter(result => result.id !== resultId));
          animateReturnToOriginalState();
        } else {
          throw new Error(`Unexpected server response: ${response.status}`);
        }
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      Alert.alert('Error', 'Failed to delete the image. Please try again.');
    }
  }, [token, animateReturnToOriginalState]);

  const handleTryOnClick = useCallback(async (productId: string) => {
    if (virtualTryOnsRemaining !== null && virtualTryOnsRemaining <= 0) {
      setShowNoTryOnsMessage(true);
      Animated.timing(noTryOnsMessageAnimation, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
      }).start();
      setTimeout(() => {
        Animated.timing(noTryOnsMessageAnimation, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }).start(() => setShowNoTryOnsMessage(false));
      }, 3000);
      return;
    }

    setIsTryingOn(true);

    try {
      if (!token) throw new Error('User not authenticated');

      const useFeatureResponse = await axios.post(`${BASE_URL}/subscription/use_feature/`,
        { feature_type: 'virtual_try_on' },
        { headers: { 'Authorization': `Token ${token}` } }
      );

      if (!useFeatureResponse.data.success) {
        throw new Error(useFeatureResponse.data.message || 'Failed to use try-on feature');
      }

      updateSubscriptionLimits({
        virtual_try_ons_remaining: useFeatureResponse.data.virtual_try_ons_remaining,
        profile_images_remaining: null,
        try_on_results_remaining: null
      });

      const response = await axios.post(`${BASE_URL}/tryon/try-on/`,
        { product_id: productId },
        { headers: { 'Authorization': `Token ${token}` } }
      );

      if (response.data && response.data.result_image) {
        const newResult = { ...response.data, created_at: new Date().toISOString() };
        setTryOnResults(prev => [newResult, ...prev]);
        
        Alert.alert('Try-On Successful', 'Your virtual try-on is ready! Check the top of the screen to see the result.');
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      console.error('Error performing try-on:', error);
      if (error instanceof Error) {
        Alert.alert('Try-On Failed', error.message);
      } else {
        Alert.alert('Try-On Failed', 'An unexpected error occurred while processing your try-on request.');
      }
    } finally {
      setIsTryingOn(false);
    }
  }, [token, virtualTryOnsRemaining, updateSubscriptionLimits, noTryOnsMessageAnimation]);

  const addToFavorites = useCallback(async (productId: string) => {
    if (favorites.has(productId)) {
      console.log('Product is already in favorites');
      return;
    }

    try {
      if (!token) throw new Error('User not authenticated');

      const response = await axios.post(`${BASE_URL}/favorites/toggle/`,
        { product: productId },
        { headers: { 'Authorization': `Token ${token}` } }
      );

      if (response.data.is_favorite) {
        setFavorites(prev => new Set(prev).add(productId));
        console.log('Product was added to favorites');
      } else {
        console.log('Failed to add product to favorites');
      }
    } catch (error) {
      console.error('Error adding to favorites:', error);
    }
  }, [token, favorites]);

  const openStoreLink = useCallback((url: string, productId: string) => {
    Linking.openURL(url).catch((err) => console.error('An error occurred', err));
    
    if (!favorites.has(productId)) {
      addToFavorites(productId);
    }
  }, [addToFavorites, favorites]);

  const enlargedStyles = {
    width: enlargeAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [210, 350]
    }),
    height: enlargeAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [320, 530]
    }),
    marginRight: enlargeAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [37, 30]
    }),
    zIndex: enlargeAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [1, 10]
    }),
  };

  const renderProductItem = useCallback(({ item }: { item: Product }) => (
    <View style={[styles.productWrapper, { width: PRODUCT_LAYOUT.WRAPPER.width, height: PRODUCT_LAYOUT.WRAPPER.height, marginRight: PRODUCT_LAYOUT.WRAPPER.marginRight }]}>
      <TouchableOpacity
        style={styles.productTouchable}
        onPress={() => openStoreLink(item.store_link, item.id)}
      >
        <Image
          source={bakcetImage as ImageSourcePropType}
          style={[styles.bakcetImage, { width: PRODUCT_LAYOUT.BAKCET.width, height: PRODUCT_LAYOUT.BAKCET.height, top: PRODUCT_LAYOUT.BAKCET.top, zIndex: PRODUCT_LAYOUT.BAKCET.zIndex }]}
          resizeMode="contain"
        />
        <Image
          source={{ uri: item.image_url }}
          style={[styles.productImage, { width: PRODUCT_LAYOUT.IMAGE.width, height: PRODUCT_LAYOUT.IMAGE.height, top: PRODUCT_LAYOUT.IMAGE.top, zIndex: PRODUCT_LAYOUT.IMAGE.zIndex }]}
          resizeMode="contain"
        />
        <Text style={[styles.productManufacturer, { fontSize: PRODUCT_LAYOUT.MANUFACTURER.fontSize, top: PRODUCT_LAYOUT.MANUFACTURER.top, zIndex: PRODUCT_LAYOUT.MANUFACTURER.zIndex }]}>
          {item.manufacturer_name}
        </Text>
        <Text style={[styles.productPrice, { fontSize: PRODUCT_LAYOUT.PRICE.fontSize, top: PRODUCT_LAYOUT.PRICE.top, zIndex: PRODUCT_LAYOUT.PRICE.zIndex }]}>
          {item.price}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.tryOnButton, { width: PRODUCT_LAYOUT.TRYON_BUTTON.width, height: PRODUCT_LAYOUT.TRYON_BUTTON.height, top: PRODUCT_LAYOUT.TRYON_BUTTON.top, left: PRODUCT_LAYOUT.TRYON_BUTTON.left, transform: PRODUCT_LAYOUT.TRYON_BUTTON.transform, zIndex: PRODUCT_LAYOUT.TRYON_BUTTON.zIndex }]}
        onPress={() => handleTryOnClick(item.id)}
        disabled={isTryingOn}
      >
        {isTryingOn ? (
          <ActivityIndicator size="small" color="#ffffff" />
        ) : (
          <Image
            source={tryonImage as ImageSourcePropType}
            style={styles.tryOnButtonImage}
            resizeMode="contain"
          />
        )}
      </TouchableOpacity>
    </View>
  ), [handleTryOnClick, isTryingOn, openStoreLink]);

  if (error) {
    return (
      <View style={styles.centered}>
        <Text style={styles.errorText}>{error}</Text>
        <TouchableOpacity style={styles.retryButton} onPress={() => {
          setError(null);
          Promise.all([fetchUserData(), fetchTryOnResults(), fetchSubscriptionDetails(), fetchProducts()]);
        }}>
          <Text style={styles.retryButtonText}>Retry</Text>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      
      <View style={styles.content}>
        <TouchableOpacity style={styles.loveButton} onPress={() => handleButtonClick('Love')}>
          <Image source={loveImage as ImageSourcePropType} style={styles.buttonImage} />
        </TouchableOpacity>
       
        <TouchableOpacity style={styles.subsButton} onPress={() => handleButtonClick('Subs')}>
          <Image source={subsImage as ImageSourcePropType} style={styles.buttonImage} />
        </TouchableOpacity>
       
        <TouchableOpacity style={styles.nandezudButton} onPress={() => handleButtonClick('Nandezud')}>
          <Image source={nandezudImage as ImageSourcePropType} style={styles.buttonImage} />
        </TouchableOpacity>

        <Animated.ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          style={[
            styles.tryOnResultsContainer,
            { height: containerHeightAnimation }
          ]}
          contentContainerStyle={styles.tryOnResultsContent}
        >
          {user && user.profile_images && user.profile_images.map((imageUrl, index) => (
            <View key={`profile-${index}`}>
              <Image
                source={{ uri: imageUrl }}
                style={styles.profileImage}
              />
            </View>
          ))}
          {sortedTryOnResults.map((result) => (
            <TouchableOpacity
              key={result.id}
              onPress={() => handleTryOnImageClick(result)}
            >
              <Animated.Image
                source={{ uri: result.result_image }}
                style={[styles.tryOnResultImage,
                  enlargedImage && enlargedImage.id === result.id ? enlargedStyles : {}
                ]}
              />
              {isEnlarged && enlargedImage && enlargedImage.id === result.id && (
                <TouchableOpacity
                  style={styles.deleteButton}
                  onPress={() => handleDeleteImage(result.id)}
                >
                  <Text style={styles.deleteButtonText}>Delete</Text>
                </TouchableOpacity>
              )}
            </TouchableOpacity>
          ))}
        </Animated.ScrollView>

        <Animated.View style={[styles.categoriesSection, { opacity: fadeAnimation, transform: [{ scale: dressButtonAnimation }] }]}>
          <TouchableOpacity onPress={() => handleButtonClick('Dress')}>
            <Text style={styles.categoriesText}>DRESSES</Text>
          </TouchableOpacity>
          <View style={styles.categoriesLine} />
        </Animated.View>

        <Animated.View style={{
          opacity: productListAnimation,
          transform: [{
            translateY: productListAnimation.interpolate({
              inputRange: [0, 1],
              outputRange: [50, 0]
            })
          }]
        }}>
          <VirtualizedList
            ref={productListRef}
            data={visibleProducts}
            renderItem={renderProductItem}
            keyExtractor={(item) => item.id}
            getItemCount={() => visibleProducts.length}
            getItem={(data, index) => data[index]}
            horizontal
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={styles.productList}
            style={[styles.productListContainer, { opacity: fadeAnimation }]}
            onEndReached={loadMoreProducts}
            onEndReachedThreshold={0.5}
            onScroll={handleScroll}
            scrollEventThrottle={16}
          />
        </Animated.View>
      </View>
      
      {isTryingOn && (
        <View style={styles.loadingOverlay}>
          <Lottie
            animationData={ufoAnimation}
            loop
            autoplay
            style={styles.loadingAnimation}
          />
          <Text style={styles.loadingText}>Dressing may take 20-50s.
          Continue using the app, result will be in profile gallery.</Text>
        </View>
      )}

      {showNoTryOnsMessage && (
        <Animated.View style={[styles.noTryOnsMessage, { opacity: noTryOnsMessageAnimation }]}>
          <Text style={styles.noTryOnsMessageText}>No Try-Ons Remaining</Text>
          <Text style={styles.noTryOnsMessageSubtext}>Upgrade your plan for more!</Text>
        </Animated.View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#020209',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '130%',
    height: '130%',
    resizeMode: 'cover',
  },
  content: {
    width: CONTENT_WIDTH,
    height: CONTENT_HEIGHT,
    position: 'relative',
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loveButton: {
    position: 'absolute',
    top: 17,
    right: 42,
    width: 22,
    height: 22,
    zIndex: 60,
  },
  subsButton: {
    position: 'absolute',
    top: 16,
    left: 42,
    width: 26,
    height: 26,
    zIndex: 60,
  },
  nandezudButton: {
    position: 'absolute',
    top: 18,
    left: CONTENT_WIDTH / 2 - 68,
    width: 130,
    height: 23,
    zIndex: 60,
  },
  buttonImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  tryOnResultsContainer: {
    position: 'absolute',
    top: 70,
    left: 0,
    right: 0,
    zIndex: 56,
  },
  tryOnResultsContent: {
    paddingLeft: CONTENT_WIDTH * 0.25,
  },
  profileImage: {
    width: 200,
    height: 320,
    marginRight: 37,
    borderRadius: 10,
  },
  tryOnResultImage: {
    width: 210,
    height: 320,
    marginRight: 70,
    borderRadius: supportsClipPath ? 0 : 10,
    clipPath: supportsClipPath ? 'inset(0 round 10px)' : 'none',
    transform: [{ scaleX: 0.89 }],
  },
  errorText: {
    color: 'red',
    fontSize: 16,
    marginBottom: 10,
  },
  retryButton: {
    backgroundColor: '#007AFF',
    padding: 10,
    borderRadius: 5,
  },
  retryButtonText: {
    color: 'white',
    fontSize: 16,
  },
  deleteButton: {
    position: 'absolute',
    bottom: 12,
    right: 160,
    width: 112,
    backgroundColor: 'red',
    padding: 10,
    borderRadius: 20,
  },
  deleteButtonText: {
    color: 'white',
    fontSize: 18,
    fontWeight: '300',
    textAlign: 'center',
  },
  categoriesSection: {
    position: 'absolute',
    top: CONTENT_HEIGHT * 0.50,
    left: 47,
    right: 20,
    zIndex: 100,
  },
  categoriesText: {
    color: 'white',
    fontSize: 24,
    fontWeight: '300',
  },
  categoriesLine: {
    height: 1,
    backgroundColor: 'white',
    marginTop: 5,
    width: '90%',
  },
  productListContainer: {
    position: 'absolute',
    top: CONTENT_HEIGHT * 0.57,
    left: -30,
    right: 0,
    height: CONTENT_HEIGHT * 0.6,
  },
  productList: {
    paddingHorizontal: SCREEN_WIDTH * 0.05,
  },
  productWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  productTouchable: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  productImage: {
    position: 'absolute',
    resizeMode: 'contain',
  },
  bakcetImage: {
    position: 'absolute',
    resizeMode: 'contain',
  },
  productManufacturer: {
    position: 'absolute',
    fontWeight: 'bold',
    color: 'white',
  },
  productPrice: {
    position: 'absolute',
    color: 'white',
  },
  tryOnButton: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tryOnButtonImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000,
  },
  loadingAnimation: {
    width: 300,
    height: 300,
  },
  loadingText: {
    color: 'white',
    fontSize: 16,
    marginTop: 50,
    textAlign: 'center',
  },
  noTryOnsMessage: {
    position: 'absolute',
    top: SCREEN_HEIGHT * 0.1,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: 20,
    alignItems: 'center',
    zIndex: 1000,
  },
  noTryOnsMessageText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  noTryOnsMessageSubtext: {
    color: 'white',
    fontSize: 14,
  },
});

export default DressScreen;