import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import axios from 'axios';
import debounce from 'lodash/debounce';

const BASE_URL = 'https://app-tdh1.onrender.com';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button<{ disabled?: boolean }>`
  width: 180px;
  height: 45px;
  margin: 8px 0;
  padding: 10px 20px;
  background-color: ${props => props.disabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.2)'};
  color: ${props => props.disabled ? 'gray' : 'black'};
  border: none;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  border-radius: 22px;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: ${props => props.disabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.3)'};
    transform: ${props => props.disabled ? 'none' : 'scale(1.05)'};
  }

  &:active {
    background-color: ${props => props.disabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.4)'};
    transform: ${props => props.disabled ? 'none' : 'scale(0.95)'};
  }
`;

const ImageContainer = styled.div<{ isPreview: boolean }>`
  width: ${props => props.isPreview ? '60vw' : '70vw'};
  height: ${props => props.isPreview ? '90vw' : '60vh'};
  border-radius: 20px;
  overflow: hidden;
  background-color: black;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
`;

interface AddPhotoModalProps {
  userId: string;
  token: string;
  onClose: () => void;
  onPhotoAdded: (success: boolean, message: string) => void;
}

const AddPhotoModal: React.FC<AddPhotoModalProps> = ({ userId, token, onClose, onPhotoAdded }) => {
  const [src, setSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 30,
    height: 45,
    x: 35,
    y: 27.5,
  });
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsProcessing(true);
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          setSrc(event.target.result as string);
          setIsProcessing(false);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const getCroppedImg = useCallback((image: HTMLImageElement, crop: PixelCrop): string => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return canvas.toDataURL('image/png');
  }, []);

  const makeClientCrop = useCallback((crop: PixelCrop) => {
    if (src && crop.width && crop.height && imageRef.current) {
      const croppedImageUrl = getCroppedImg(imageRef.current, crop);
      setCroppedImageUrl(croppedImageUrl);
    }
  }, [src, getCroppedImg]);

  const debouncedMakeClientCrop = useMemo(
    () => debounce((crop: PixelCrop) => makeClientCrop(crop), 300),
    [makeClientCrop]
  );

  const onCropChange = (crop: Crop) => {
    setCrop(crop);
  };

  const onCropComplete = (crop: PixelCrop) => {
    debouncedMakeClientCrop(crop);
  };

  const uploadPhoto = async () => {
    if (!croppedImageUrl) {
      onPhotoAdded(false, 'No image to upload');
      return;
    }

    setIsUploading(true);

    try {
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();
      const formData = new FormData();
      formData.append('profile_image', blob, 'photo.png');
      const uploadResponse = await axios.post(
        `${BASE_URL}/user/users/${userId}/upload_profile_image/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Token ${token}`,
          },
        }
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        onPhotoAdded(true, 'Photo uploaded successfully');
        onClose();
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      console.error('Error uploading photo:', error);
      onPhotoAdded(false, 'Failed to upload photo. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    return () => {
      debouncedMakeClientCrop.cancel();
    };
  }, [debouncedMakeClientCrop]);

  return (
    <Modal>
      <ModalContent>
        {!src && (
          <>
            <h2>Select a photo to upload</h2>
            <p>Add a high-quality, well-lit photograph, preferably a full-body shot.</p>
            <Button onClick={() => inputRef.current?.click()} disabled={isProcessing}>
              Select Photo
            </Button>
            <input
              ref={inputRef}
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              style={{ display: 'none' }}
            />
            {isProcessing && <LoadingOverlay>Processing image...</LoadingOverlay>}
          </>
        )}

        {src && !isPreviewMode && (
          <>
            <ImageContainer isPreview={false}>
              <ReactCrop
                crop={crop}
                onChange={onCropChange}
                onComplete={onCropComplete}
                aspect={2 / 3}
              >
                <img 
                  ref={imageRef}
                  src={src} 
                  alt="Upload" 
                  style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} 
                />
              </ReactCrop>
            </ImageContainer>
            <Button onClick={() => setIsPreviewMode(true)} disabled={isUploading}>
              Preview
            </Button>
          </>
        )}

        {isPreviewMode && croppedImageUrl && (
          <>
            <ImageContainer isPreview={true}>
              <PreviewImage src={croppedImageUrl} alt="Preview" />
            </ImageContainer>
            <Button onClick={uploadPhoto} disabled={isUploading}>
              {isUploading ? 'Uploading...' : 'Confirm'}
            </Button>
            <Button 
              onClick={() => {
                setSrc(null);
                setCroppedImageUrl(null);
                setIsPreviewMode(false);
                setCrop({
                  unit: '%',
                  width: 30,
                  height: 45,
                  x: 35,
                  y: 27.5,
                });
              }} 
              disabled={isUploading}
            >
              Repeat
            </Button>
            <Button onClick={() => setIsPreviewMode(false)} disabled={isUploading}>
              Back
            </Button>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default AddPhotoModal;