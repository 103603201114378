import React from 'react';

const FAQ: React.FC = () => (
  <div>
    <h1>Frequently Asked Questions</h1>

    <h2>General Questions</h2>

    <h3>1. Q: What is Nandezu?</h3>
    <p>A: Nandezu is an innovative fashion app that allows you to virtually try on clothes from over 400 brands and 15,000 products using advanced AI technology.</p>

    <h3>2. Q: How do I create an account?</h3>
    <p>A: Download the Nandezu app, open it, and click on the 'Sign Up' button. Follow the prompts to complete your registration.</p>

    <h3>3. Q: Is Nandezu available in my country?</h3>
    <p>A: Nandezu is available globally. However, some features or brands may be limited in certain regions due to licensing agreements.</p>

    <h3>4. Q: Is there an age restriction for using Nandezu?</h3>
    <p>A: Users must be at least 13 years old to use Nandezu. Users under 18 should have parental consent.</p>

    <h2>Using the App</h2>

    <h3>5. Q: How does the virtual try-on feature work?</h3>
    <p>A: Our AI technology overlays clothing items onto your uploaded photos, allowing you to see how they would look on you.</p>

    <h3>6. Q: How many photos can I upload?</h3>
    <p>A: You can upload up to 10 profile photos for virtual try-ons.</p>

    <h3>7. Q: What types of photos work best?</h3>
    <p>A: Full-body shots with good lighting work best. Wear form-fitting clothes and stand against a plain background for optimal results.</p>

    <h3>8. Q: Can I try on accessories like hats or jewelry?</h3>
    <p>A: Currently, Nandezu focuses on clothing items. We're working on adding accessories in future updates.</p>

    <h3>9. Q: How many virtual try-ons do I get?</h3>
    <p>A: Free users get 5 virtual try-ons. Premium users ($9.99/month) get 50 try-ons.</p>

    <h3>10. Q: Can I undo a virtual try-on if I'm not satisfied?</h3>
    <p>A: Yes, you can undo and redo try-ons without using additional credits.</p>

    <h2>AI Chatbot</h2>

    <h3>11. Q: What is the AI chatbot?</h3>
    <p>A: The AI chatbot is a virtual fashion advisor available to premium users. It provides personalized style advice and outfit suggestions.</p>

    <h3>12. Q: How do I access the AI chatbot?</h3>
    <p>A: Premium users can access the chatbot from the main menu. Look for the "Fashion Advisor" button.</p>

    <h3>13. Q: Can the AI chatbot recommend outfits for specific occasions?</h3>
    <p>A: Yes, you can ask the chatbot for outfit recommendations for various occasions, from casual outings to formal events.</p>

    <h3>14. Q: Is the AI chatbot available 24/7?</h3>
    <p>A: Yes, the AI chatbot is available round the clock for your fashion queries.</p>

    <h2>Subscription and Payments</h2>

    <h3>15. Q: What's included in the premium package?</h3>
    <p>A: The premium package ($9.99/month) includes 50 virtual try-ons and access to the AI chatbot fashion advisor.</p>

    <h3>16. Q: How do I purchase credits or subscribe?</h3>
    <p>A: Go to the 'Buy Credit' section in the app. We accept credit cards, Google Pay, and Apple Pay through our secure Stripe payment system.</p>

    <h3>17. Q: Can I cancel my subscription anytime?</h3>
    <p>A: Yes, you can cancel your subscription at any time. Your premium features will remain active until the end of your billing cycle.</p>

    <h3>18. Q: Do unused try-ons roll over to the next month?</h3>
    <p>A: No, unused try-ons do not roll over. They reset at the beginning of each billing cycle.</p>

    <h2>Cash Back Feature</h2>

    <h3>19. Q: What is the cash back feature?</h3>
    <p>A: Our upcoming cash back feature will allow you to earn money back on your purchases through the app.</p>

    <h3>20. Q: How does the cash back comparison work?</h3>
    <p>A: The app will compare cash back offers from 1-5 shops to help you find the best deal on your desired items.</p>

    <h3>21. Q: When will the cash back feature be available?</h3>
    <p>A: We're working hard to launch this feature soon. Stay tuned for announcements in the app and via email.</p>

    <h2>Privacy and Security</h2>

    <h3>22. Q: Is my personal information secure?</h3>
    <p>A: Yes, we use industry-standard encryption and security measures to protect your personal information.</p>

    <h3>23. Q: What happens to the photos I upload?</h3>
    <p>A: Your photos are encrypted on our servers and used solely for the virtual try-on feature. They are not shared with third parties.</p>

    <h3>24. Q: Can I delete my photos from your servers?</h3>
    <p>A: Yes, you can delete individual photos or all your photos from your account settings at any time.</p>

    <h3>25. Q: How do you use my shopping data?</h3>
    <p>A: We use anonymized shopping data to improve our recommendations and app functionality. We never sell individual user data.</p>

    <h2>Technical Questions</h2>

    <h3>26. Q: Which devices is Nandezu compatible with?</h3>
    <p>A: Nandezu is available for iOS and Android devices. Check your device's app store for specific compatibility requirements.</p>

    <h3>27. Q: How much storage space does the app require?</h3>
    <p>A: The app typically requires around 100MB of storage, but this may vary depending on your device and usage.</p>

    <h3>28. Q: Can I use Nandezu offline?</h3>
    <p>A: An internet connection is required for most features, including virtual try-ons and accessing the AI chatbot.</p>

    <h3>29. Q: How do I update the app?</h3>
    <p>A: The app should update automatically. If not, you can manually update it through your device's app store.</p>

    <h2>Content and Brands</h2>

    <h3>30. Q: How often are new products added?</h3>
    <p>A: We update our product catalog regularly. Check the 'What's New' section for the latest additions.</p>

    <h3>31. Q: Can I request specific brands or products to be added?</h3>
    <p>A: We're always open to suggestions! You can submit brand or product requests through the app's feedback feature.</p>

    <h3>32. Q: Are the prices in the app real-time?</h3>
    <p>A: We strive to keep prices up-to-date, but they may occasionally differ from the retailer's current price. Always check the final price before purchasing.</p>

    <h2>Account Management</h2>

    <h3>33. Q: How do I reset my password?</h3>
    <p>A: Go to the login screen and click on "Forgot Password". Follow the instructions sent to your registered email.</p>

    <h3>34. Q: Can I change my username?</h3>
    <p>A: Yes, you can change your username in the account settings section of the app.</p>

    <h3>35. Q: How do I delete my account?</h3>
    <p>A: You can request account deletion through the app settings. This will remove all your personal data and photos from our servers.</p>

    <h2>Customer Support</h2>

    <h3>36. Q: How can I contact customer support?</h3>
    <p>A: You can reach our customer support team by emailing info@nandezu.com or by using the contact form within the app.</p>

    <h3>37. Q: What's your response time for support queries?</h3>
    <p>A: We aim to respond to all queries within 24 hours during business days.</p>

    <h2>Miscellaneous</h2>

    <h3>38. Q: Can I share my virtual try-ons on social media?</h3>
    <p>A: Yes, you can share your virtual try-ons directly to various social media platforms from within the app.</p>

    <h3>39. Q: Do you have a referral program?</h3>
    <p>A: We're working on a referral program. Stay tuned for announcements about its launch!</p>

    <h3>40. Q: How can I provide feedback or suggest new features?</h3>
    <p>A: We love hearing from our users! You can submit feedback or suggestions through the 'Feedback' section in the app settings.</p>
  </div>
);

export default FAQ;