import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  ImageSourcePropType,
  SafeAreaView,
  Animated,
  Easing
} from 'react-native';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import Lottie from 'lottie-react';
import { useSubscription } from '../contexts/SubscriptionContext';

// Import images

import nandezudImage from '../assets/images/nandezu.png';
import loveImage from '../assets/images/love.png';
import subsImage from '../assets/images/subs.png';
import blackbolImage from '../assets/images/blackbol.png';
import buyImage from '../assets/images/buy.png';
import catAnimation from '../assets/cat.json';

// Base URL for API requests
const BASE_URL = 'https://app-tdh1.onrender.com';

// Define type for user
interface User {
  id: string;
  username: string;
}

const SubScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams<{ userId: string }>();
  const { token } = location.state as { token: string };

  const { virtualTryOnsRemaining, updateSubscriptionLimits } = useSubscription();

  const [user, setUser] = useState<User | null>(null);
  const [userGreeting, setUserGreeting] = useState<string>('');
  const transitionAnimation = useRef(new Animated.Value(0)).current;

  const fetchUserData = useCallback(async () => {
    try {
      if (!token || !userId) throw new Error('User not authenticated');

      const response = await axios.get(`${BASE_URL}/users/${userId}/`, {
        headers: { 'Authorization': `Token ${token}` }
      });

      if (response.data) {
        setUser(response.data);
      } else {
        throw new Error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [token, userId]);

  const fetchSubscriptionDetails = useCallback(async () => {
    try {
      if (!token) throw new Error('User not authenticated');

      const response = await axios.get(`${BASE_URL}/subscription/details/`, {
        headers: { 'Authorization': `Token ${token}` }
      });

      updateSubscriptionLimits(response.data);
    } catch (error) {
      console.error('Error fetching subscription details:', error);
    }
  }, [token, updateSubscriptionLimits]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchUserData(), fetchSubscriptionDetails()]);
    };

    fetchData();

    Animated.timing(transitionAnimation, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
      easing: Easing.out(Easing.ease)
    }).start();
  }, [fetchUserData, fetchSubscriptionDetails, transitionAnimation]);

  useEffect(() => {
    if (user && user.username) {
      setUserGreeting(`Hi, ${user.username}!`);
    }
  }, [user]);

  const redirectToStripe = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/buy-credits/`, {
        headers: { 'Authorization': `Token ${token}` }
      });
      
      const paymentLink = response.data.payment_link;
      
      window.location.href = paymentLink;
    } catch (error) {
      console.error('Error redirecting to Stripe:', error);
    }
  };

  const handleButtonClick = (buttonName: string) => {
    console.log(`${buttonName} button clicked`);
    switch (buttonName) {
      case 'Love':
          navigate(`/manage-profile-picture/${userId}`, { state: { token, userId } });
          break;
        case 'Subs':
          navigate(`/menu-screen/${userId}`, { state: { token, userId } });
          break;
        case 'Nandezud':
          navigate(`/user/${userId}`, { state: { token } });
        break;
      case 'Buy':
        redirectToStripe();
        break;
    }
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <Animated.View style={[
        styles.container,
        {
          opacity: transitionAnimation,
          transform: [{
            translateY: transitionAnimation.interpolate({
              inputRange: [0, 1],
              outputRange: [50, 0]
            })
          }]
        }
      ]}>
        
        
        <TouchableOpacity style={styles.loveButton} onPress={() => handleButtonClick('Love')}>
          <Image source={loveImage as ImageSourcePropType} style={styles.buttonImage} />
        </TouchableOpacity>
       
        <TouchableOpacity style={styles.subsButton} onPress={() => handleButtonClick('Subs')}>
          <Image source={subsImage as ImageSourcePropType} style={styles.buttonImage} />
        </TouchableOpacity>
       
        <TouchableOpacity style={styles.nandezudButton} onPress={() => handleButtonClick('Nandezud')}>
          <Image source={nandezudImage as ImageSourcePropType} style={styles.buttonImage} />
        </TouchableOpacity>

        <TouchableOpacity style={styles.buyButton} onPress={() => handleButtonClick('Buy')}>
          <Image source={buyImage as ImageSourcePropType} style={styles.buttonImage} />
        </TouchableOpacity>

        <Image source={blackbolImage as ImageSourcePropType} style={styles.blackbolImage} />

        <Text style={styles.userGreeting}>{userGreeting}</Text>

        {virtualTryOnsRemaining !== null && (
          <Text style={styles.tryOnsRemainingText}>
            Try-ons remaining: {virtualTryOnsRemaining}
          </Text>
        )}

        <View style={styles.lottieContainer}>
          <Lottie
            animationData={catAnimation}
            style={styles.lottieAnimation}
            loop={true}
            autoplay={true}
          />
        </View>
      </Animated.View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#020209',
  },
  container: {
    flex: 1,
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  loveButton: {
    position: 'absolute',
    top: '2.7%',
    right: '5%',
    width: '6%',
    aspectRatio: 1,
    zIndex: 10,
  },
  subsButton: {
    position: 'absolute',
    top: '2.7%',
    left: '5%',
    width: '7%',
    aspectRatio: 1,
    zIndex: 10,
  },
  nandezudButton: {
    position: 'absolute',
    top: '2.5%',
    left: '50%',
    width: '30%',
    height: '4%',
    transform: [{ translateX: '-50%' }],
    zIndex: 10,
  },
  buyButton: {
    position: 'absolute',
    top: '56%',
    right: '38%',
    width: '25%',
    aspectRatio: 1,
    zIndex: 10,
  },
  buttonImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  blackbolImage: {
    position: 'absolute',
    top: '15%',
    left: '0%',
    width: '100%',
    aspectRatio: 1,
    resizeMode: 'contain',
    zIndex: 2,
  },
  userGreeting: {
    position: 'absolute',
    top: '18%',
    left: '39%',
    fontSize: 17,
    fontWeight: 'light',
    color: 'white',
    zIndex: 6,
  },
  tryOnsRemainingText: {
    position: 'absolute',
    top: '50%',
    left: '33%',
    color: 'white',
    fontSize: 13,
    fontWeight: 'light',
    zIndex: 7,
  },
  lottieContainer: {
    position: 'absolute',
    top: '50%',
    left: '47%',
    width: '50%',
    height: '50%',
    zIndex: 5,
  },
  lottieAnimation: {
    width: '100%',
    height: '100%',
  },
});

export default SubScreen;