import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  ImageSourcePropType,
  ScrollView,
  Alert,
  Platform
} from 'react-native';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import AddPhotoModal from './AddPhotoModal';

// Import images

import nandezudImage from '../assets/images/nandezu.png';
import loveImage from '../assets/images/love.png';
import subsImage from '../assets/images/subs.png';

const CONTENT_WIDTH = 420;
const CONTENT_HEIGHT = 812;

const BASE_URL = 'https://app-tdh1.onrender.com';

// Constants for button positioning and sizing
const LOVE_BUTTON_TOP = 22;
const LOVE_BUTTON_RIGHT = 76;
const LOVE_BUTTON_SIZE = 23;

const SUBS_BUTTON_TOP = 22;
const SUBS_BUTTON_LEFT = 21;
const SUBS_BUTTON_SIZE = 26;

const NANDEZUD_BUTTON_TOP = 24;
const NANDEZUD_BUTTON_WIDTH = 130;

// Constants for image sizing and spacing
const IMAGE_WIDTH = 264;
const IMAGE_HEIGHT = 411;
const IMAGE_SPACING = 30;

const ManageProfilePicture: React.FC = () => {
  const [profileImages, setProfileImages] = useState<string[]>([]);
  const [activeImageUrl, setActiveImageUrl] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const scrollViewRef = useRef<ScrollView>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams<{ userId: string }>();
  const token = location.state?.token as string | undefined;

  const fetchProfileImages = useCallback(async () => {
    if (!userId || !token) {
      console.error('UserId or token is missing');
      return;
    }
    try {
      const response = await axios.get(`${BASE_URL}/users/${userId}/`, {
        headers: { 'Authorization': `Token ${token}` }
      });
      setProfileImages(response.data.profile_images);
      setActiveImageUrl(response.data.profile_images[0] || '');
    } catch (error) {
      console.error('Error fetching profile images:', error);
    }
  }, [userId, token]);

  useEffect(() => {
    fetchProfileImages();
  }, [fetchProfileImages]);

  const handleAddPhoto = () => {
    setIsModalOpen(true);
  };

  const handlePhotoAdded = (success: boolean, message: string) => {
    if (success) {
      fetchProfileImages().then(() => {
        // Scroll to the end after fetching new images
        setTimeout(() => {
          scrollViewRef.current?.scrollToEnd({ animated: true });
        }, 100);
      });
    }
    Alert.alert('Photo Upload', message);
  };

  const handleSetActiveImage = async (imageUrl: string) => {
    if (!userId || !token) {
      console.error('UserId or token is missing');
      return;
    }
    try {
      await axios.post(`${BASE_URL}/users/${userId}/set_active_profile_image/`, 
        { image_url: imageUrl },
        { headers: { 'Authorization': `Token ${token}` } }
      );
      setActiveImageUrl(imageUrl);
      fetchProfileImages();
    } catch (error) {
      console.error('Error setting active image:', error);
    }
  };

  const handleRemoveImage = async (imageUrl: string) => {
    if (!userId || !token) {
      console.error('UserId or token is missing');
      return;
    }
    if (imageUrl === activeImageUrl) {
      Alert.alert('Cannot Delete', 'You cannot delete the active profile image.');
      return;
    }

    try {
      const isConfirmed = await new Promise<boolean>((resolve) => {
        if (Platform.OS === 'web') {
          resolve(window.confirm("Are you sure you want to remove this image?"));
        } else {
          Alert.alert(
            "Confirmation",
            "Are you sure you want to remove this image?",
            [
              { text: "No", onPress: () => resolve(false), style: "cancel" },
              { text: "Yes", onPress: () => resolve(true) }
            ]
          );
        }
      });

      if (isConfirmed) {
        await axios.delete(`${BASE_URL}/users/${userId}/remove_profile_image/`, {
          headers: { 'Authorization': `Token ${token}` },
          data: { image_url: imageUrl }
        });
        fetchProfileImages();
      }
    } catch (error) {
      console.error('Error removing image:', error);
    }
  };

  const handleButtonClick = (buttonName: string) => {
    if (!userId) {
      console.error('UserId is missing');
      return;
    }
    console.log(`${buttonName} button clicked`);
    switch (buttonName) {
      case 'Love':
        // Handle Love button click (no action)
        break;
      case 'Subs':
        navigate(`/menu-screen/${userId}`, { state: { token } });
        break;
      case 'Nandezud':
        navigate(`/user/${userId}`, { state: { token } });
        break;
    }
  };

  if (!userId || !token) {
    return <Text>Error: Missing user ID or token</Text>;
  }

  return (
    <View style={styles.container}>
      
      
      <TouchableOpacity style={styles.loveButton} onPress={() => handleButtonClick('Love')}>
        <Image source={loveImage as ImageSourcePropType} style={styles.buttonImage} />
      </TouchableOpacity>
      
      <TouchableOpacity style={styles.subsButton} onPress={() => handleButtonClick('Subs')}>
        <Image source={subsImage as ImageSourcePropType} style={styles.buttonImage} />
      </TouchableOpacity>
      
      <TouchableOpacity style={styles.nandezudButton} onPress={() => handleButtonClick('Nandezud')}>
        <Image source={nandezudImage as ImageSourcePropType} style={styles.buttonImage} />
      </TouchableOpacity>

      <Text style={styles.title}>Manage Profile Pictures</Text>

      <ScrollView 
        ref={scrollViewRef}
        horizontal={true}
        style={styles.scrollView}
        contentContainerStyle={styles.imageScrollContainer}
        showsHorizontalScrollIndicator={false}
      >
        <View style={styles.spacer} />
        {profileImages.map((image, index) => (
          <View key={index} style={styles.imageContainer}>
            <Image source={{ uri: image }} style={styles.profileImage} />
            <View style={styles.buttonContainer}>
              <TouchableOpacity
                style={[styles.actionButton, image === activeImageUrl && styles.activeButton]}
                onPress={() => handleSetActiveImage(image)}
                disabled={image === activeImageUrl}
              >
                <Text style={styles.buttonText}>{image === activeImageUrl ? 'Active' : 'Set as Main'}</Text>
              </TouchableOpacity>
              {image !== activeImageUrl && (
                <TouchableOpacity
                  style={[styles.actionButton, styles.deleteButton]}
                  onPress={() => handleRemoveImage(image)}
                >
                  <Text style={styles.buttonText}>Delete</Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
        ))}
        <View style={styles.spacer} />
      </ScrollView>

      <TouchableOpacity style={styles.addButton} onPress={handleAddPhoto}>
        <Text style={styles.addButtonText}>Add New Photo</Text>
      </TouchableOpacity>

      {isModalOpen && (
        <AddPhotoModal
          userId={userId}
          token={token}
          onClose={() => setIsModalOpen(false)}
          onPhotoAdded={handlePhotoAdded}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: CONTENT_WIDTH,
    height: CONTENT_HEIGHT,
    overflow: 'hidden',
  },
  backgroundImage: {
    position: 'absolute',
    width: CONTENT_WIDTH,
    height: CONTENT_HEIGHT,
    resizeMode: 'cover',
  },
  loveButton: {
    position: 'absolute',
    top: LOVE_BUTTON_TOP,
    right: LOVE_BUTTON_RIGHT,
    width: LOVE_BUTTON_SIZE,
    height: LOVE_BUTTON_SIZE,
    zIndex: 10,
  },
  subsButton: {
    position: 'absolute',
    top: SUBS_BUTTON_TOP,
    left: SUBS_BUTTON_LEFT,
    width: SUBS_BUTTON_SIZE,
    height: SUBS_BUTTON_SIZE,
    zIndex: 10,
  },
  nandezudButton: {
    position: 'absolute',
    top: NANDEZUD_BUTTON_TOP,
    left: (CONTENT_WIDTH - NANDEZUD_BUTTON_WIDTH) / 2.4,
    width: NANDEZUD_BUTTON_WIDTH,
    height: LOVE_BUTTON_SIZE,
    zIndex: 10,
  },
  buttonImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  title: {
    position: 'absolute',
    left: CONTENT_WIDTH * 0.2,
    top: 75,
    fontSize: 20,
    fontWeight: '300',
    color: 'white',
    textAlign: 'center',
  },
  scrollView: {
    position: 'absolute',
    top: 0,
    left: -25,
    right: 0,
    bottom: 100,
  },
  imageScrollContainer: {
    alignItems: 'center',
    paddingHorizontal: IMAGE_SPACING / 6,
  },
  spacer: {
    width: (CONTENT_WIDTH - IMAGE_WIDTH) / 4,
  },
  imageContainer: {
    marginHorizontal: IMAGE_SPACING / 1,
    alignItems: 'center',
    width: IMAGE_WIDTH,
  },
  profileImage: {
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    borderRadius: 20,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: IMAGE_WIDTH,
    marginTop: 10,
  },
  actionButton: {
    backgroundColor: 'transparent',
    borderColor: 'white',
    borderWidth: 1,
    padding: 5,
    borderRadius: 20,
    marginHorizontal: 5,
    width: IMAGE_WIDTH * 0.4,
  },
  deleteButton: {
    backgroundColor: 'red',
    borderColor: 'red',
  },
  activeButton: {
    borderColor: 'white',
  },
  buttonText: {
    color: 'white',
    fontWeight: '300',
    textAlign: 'center',
    fontSize: 12,
  },
  addButton: {
    position: 'absolute',
    backgroundColor: 'transparent',
    borderColor: 'white',
    borderWidth: 1,
    padding: 10,
    borderRadius: 20,
    top: 610,
    left: (CONTENT_WIDTH - IMAGE_WIDTH * 0.8) / 2.8,
    width: IMAGE_WIDTH * 0.8,
    alignItems: 'center',
  },
  addButtonText: {
    fontSize: 16,
    color: 'white',
    fontWeight: '300',
  },
});

export default ManageProfilePicture;