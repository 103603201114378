import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  ImageSourcePropType,
  Alert,
  Animated,
  Platform,
  Easing,
  ScrollView,
  Dimensions,
} from 'react-native';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import Lottie from 'lottie-react';
import { useSubscription } from '../contexts/SubscriptionContext';

// Import obrázků a animací
import nandezudImage from '../assets/images/nandezu.png';
import loveImage from '../assets/images/love.png';
import subsImage from '../assets/images/subs.png';
import allpImage from '../assets/images/allp.png';
import aipImage from '../assets/images/aip.png';
import mepImage from '../assets/images/mep.png';
import newpImage from '../assets/images/newp.png';
import tryonbImage from '../assets/images/tryonb.png';
import catAnimation from '../assets/cat.json';

const BASE_URL = 'https://app-tdh1.onrender.com';

// Získání rozměrů obrazovky
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');

// Definice pevných rozměrů obsahu
const CONTENT_WIDTH = 420;
const CONTENT_HEIGHT = 812;

const supportsClipPath = CSS.supports("clip-path", "inset(0 round 10px)");

interface User {
  id: string;
  username: string;
  email: string;
  subscription_type: string;
  profile_images: string[];
}

interface TryOnResult {
  id: string;
  result_image: string;
  created_at: string;
}

const UserScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams<{ userId: string }>();
  const { token } = location.state as { token: string };

  const { virtualTryOnsRemaining, updateSubscriptionLimits } = useSubscription();

  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [tryOnResults, setTryOnResults] = useState<TryOnResult[]>([]);
  const [sortedTryOnResults, setSortedTryOnResults] = useState<TryOnResult[]>([]);
  const [enlargedImage, setEnlargedImage] = useState<TryOnResult | null>(null);
  const [isEnlarged, setIsEnlarged] = useState(false);
  const [userGreeting, setUserGreeting] = useState<string>('');

  const enlargeAnimation = useRef(new Animated.Value(0)).current;
  const fadeAnimation = useRef(new Animated.Value(1)).current;
  const screenFadeAnim = useRef(new Animated.Value(1)).current;
  const containerHeightAnimation = useRef(new Animated.Value(400)).current;

  const fetchUserData = useCallback(async () => {
    try {
      if (!token || !userId) throw new Error('User not authenticated');

      const response = await axios.get(`${BASE_URL}/users/${userId}/`, {
        headers: { 'Authorization': `Token ${token}` }
      });

      if (response.data) {
        setUser(response.data);
      } else {
        throw new Error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to load user data.');
    }
  }, [token, userId]);

  const fetchTryOnResults = useCallback(async () => {
    try {
      if (!token) throw new Error('User not authenticated');

      const response = await axios.get(`${BASE_URL}/tryon/results/`, {
        headers: { 'Authorization': `Token ${token}` }
      });
     
      setTryOnResults(response.data);
    } catch (error) {
      console.error('Error fetching try-on results:', error);
    }
  }, [token]);

  const fetchSubscriptionDetails = useCallback(async () => {
    try {
      if (!token) throw new Error('User not authenticated');

      const response = await axios.get(`${BASE_URL}/subscription/details/`, {
        headers: { 'Authorization': `Token ${token}` }
      });

      updateSubscriptionLimits(response.data);
    } catch (error) {
      console.error('Error fetching subscription details:', error);
    }
  }, [token, updateSubscriptionLimits]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchUserData(), fetchTryOnResults(), fetchSubscriptionDetails()]);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data.');
      }
    };

    fetchData();
  }, [fetchUserData, fetchTryOnResults, fetchSubscriptionDetails]);

  useEffect(() => {
    const sorted = [...tryOnResults].sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
    setSortedTryOnResults(sorted);
  }, [tryOnResults]);

  useEffect(() => {
    if (user && user.username) {
      setUserGreeting(`Hi, ${user.username}!`);
    }
  }, [user]);

  const handleButtonClick = useCallback((buttonName: string) => {
    if (!userId) {
      console.error('UserId is missing');
      return;
    }

    Animated.timing(screenFadeAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      switch (buttonName) {
        case 'Love':
          navigate(`/manage-profile-picture/${userId}`, { state: { token, userId } });
          break;
        case 'Subs':
          navigate(`/menu-screen/${userId}`, { state: { token, userId } });
          break;
        case 'Nandezud':
          navigate(`/user/${userId}`, { state: { token } });
          break;
        case 'All':
          navigate(`/all-screen/${userId}`, { state: { token } });
          break;
        case 'AI':
          navigate(`/ai-screen/${userId}`, { state: { token, userId } });
          break;
        case 'Me':
          navigate(`/my-screen/${userId}`, { state: { token, userId } });
          break;
        case 'New':
          navigate(`/new-screen/${userId}`, { state: { token, userId } });
          break;
        case 'TryOn':
          navigate(`/sub-screen/${userId}`, { state: { token } });
          break;
      }
    });
  }, [navigate, token, userId, screenFadeAnim]);

  const animateReturnToOriginalState = useCallback(() => {
    setIsEnlarged(false);
    setEnlargedImage(null);

    Animated.parallel([
      Animated.timing(enlargeAnimation, {
        toValue: 0,
        duration: 600,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.cubic)
      }),
      Animated.timing(fadeAnimation, {
        toValue: 1,
        duration: 600,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.cubic)
      }),
      Animated.timing(containerHeightAnimation, {
        toValue: 400,
        duration: 600,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.cubic)
      })
    ]).start();
  }, [enlargeAnimation, fadeAnimation, containerHeightAnimation]);

  const handleTryOnImageClick = useCallback((result: TryOnResult) => {
    if (enlargedImage && enlargedImage.id === result.id) {
      animateReturnToOriginalState();
    } else {
      setEnlargedImage(result);
      setIsEnlarged(true);
      Animated.parallel([
        Animated.timing(enlargeAnimation, {
          toValue: 1,
          duration: 600,
          useNativeDriver: false,
          easing: Easing.inOut(Easing.cubic)
        }),
        Animated.timing(fadeAnimation, {
          toValue: 0,
          duration: 600,
          useNativeDriver: false,
          easing: Easing.inOut(Easing.cubic)
        }),
        Animated.timing(containerHeightAnimation, {
          toValue: 600,
          duration: 600,
          useNativeDriver: false,
          easing: Easing.inOut(Easing.cubic)
        })
      ]).start();
    }
  }, [enlargedImage, enlargeAnimation, fadeAnimation, containerHeightAnimation, animateReturnToOriginalState]);

  const handleDeleteImage = useCallback(async (resultId: string) => {
    try {
      if (!token) {
        throw new Error('User not authenticated');
      }

      const isConfirmed = await new Promise<boolean>((resolve) => {
        if (Platform.OS === 'web') {
          resolve(window.confirm("Are you sure you want to delete this image?"));
        } else {
          Alert.alert(
            "Confirmation",
            "Are you sure you want to delete this image?",
            [
              { text: "No", onPress: () => resolve(false), style: "cancel" },
              { text: "Yes", onPress: () => resolve(true) }
            ]
          );
        }
      });

      if (isConfirmed) {
        const response = await axios.delete(`${BASE_URL}/tryon/delete-result/${resultId}/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        if (response.status === 204 || response.status === 200) {
          setTryOnResults(prev => prev.filter(result => result.id !== resultId));
          animateReturnToOriginalState();
        } else {
          throw new Error(`Unexpected server response: ${response.status}`);
        }
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      Alert.alert('Error', 'Failed to delete the image. Please try again.');
    }
  }, [token, animateReturnToOriginalState]);

  const enlargedStyles = {
    width: enlargeAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [210, 350]
    }),
    height: enlargeAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [320, 530]
    }),
    marginRight: enlargeAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [37, 30]
    }),
    zIndex: enlargeAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [1, 10]
    }),
    
  };
  if (error) {
    return (
      <View style={styles.centered}>
        <Text style={styles.errorText}>{error}</Text>
        <TouchableOpacity style={styles.retryButton} onPress={() => {
          setError(null);
          Promise.all([fetchUserData(), fetchTryOnResults(), fetchSubscriptionDetails()]);
        }}>
          <Text style={styles.retryButtonText}>Retry</Text>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <ScrollView 
        contentContainerStyle={styles.scrollViewContent}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.content}>
          <Text style={styles.userGreeting}>{userGreeting}</Text>
          
          {virtualTryOnsRemaining !== null && (
            <Text style={styles.tryOnsRemainingText}>
              Try-ons left {virtualTryOnsRemaining}
            </Text>
          )}

          <TouchableOpacity style={styles.loveButton} onPress={() => handleButtonClick('Love')}>
            <Image source={loveImage as ImageSourcePropType} style={styles.buttonImage} />
          </TouchableOpacity>
         
          <TouchableOpacity style={styles.subsButton} onPress={() => handleButtonClick('Subs')}>
            <Image source={subsImage as ImageSourcePropType} style={styles.buttonImage} />
          </TouchableOpacity>
         
          <TouchableOpacity style={styles.nandezudButton} onPress={() => handleButtonClick('Nandezud')}>
            <Image source={nandezudImage as ImageSourcePropType} style={styles.buttonImage} />
          </TouchableOpacity>

          <TouchableOpacity style={styles.allButton} onPress={() => handleButtonClick('All')}>
            <Image source={allpImage as ImageSourcePropType} style={styles.buttonImage} />
          </TouchableOpacity>

          <TouchableOpacity style={styles.aiButton} onPress={() => handleButtonClick('AI')}>
            <Image source={aipImage as ImageSourcePropType} style={styles.buttonImage} />
          </TouchableOpacity>

          <TouchableOpacity style={styles.meButton} onPress={() => handleButtonClick('Me')}>
            <Image source={mepImage as ImageSourcePropType} style={styles.buttonImage} />
          </TouchableOpacity>

          <TouchableOpacity style={styles.newButton} onPress={() => handleButtonClick('New')}>
            <Image source={newpImage as ImageSourcePropType} style={styles.buttonImage} />
          </TouchableOpacity>

          <TouchableOpacity style={styles.tryonButton} onPress={() => handleButtonClick('TryOn')}>
            <Image source={tryonbImage as ImageSourcePropType} style={styles.buttonImage} />
          </TouchableOpacity>

          <View style={styles.lottieContainer}>
            <Lottie
              animationData={catAnimation}
              style={styles.lottieAnimation}
              loop={true}
              autoplay={true}
            />
          </View>

          <Animated.ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            style={[
              styles.tryOnResultsContainer,
              { height: containerHeightAnimation }
            ]}
            contentContainerStyle={styles.tryOnResultsContent}
          >
            {user && user.profile_images && user.profile_images.map((imageUrl, index) => (
              <View key={`profile-${index}`}>
                <Image
                  source={{ uri: imageUrl }}
                  style={styles.profileImage}
                />
              </View>
            ))}
            {sortedTryOnResults.map((result) => (
              <TouchableOpacity
                key={result.id}
                onPress={() => handleTryOnImageClick(result)}
              >
                <Animated.Image
                  source={{ uri: result.result_image }}
                  style={[styles.tryOnResultImage,
                    enlargedImage && enlargedImage.id === result.id ? enlargedStyles : {}
                  ]}
                />
                {isEnlarged && enlargedImage && enlargedImage.id === result.id && (
                  <TouchableOpacity
                    style={styles.deleteButton}
                    onPress={() => handleDeleteImage(result.id)}
                  >
                    <Text style={styles.deleteButtonText}>Delete</Text>
                  </TouchableOpacity>
                )}
              </TouchableOpacity>
            ))}
          </Animated.ScrollView>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: SCREEN_WIDTH,
    height: SCREEN_HEIGHT,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#020209', // Změněno na požadovanou barvu pozadí
  },
  scrollViewContent: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    width: CONTENT_WIDTH,
    height: CONTENT_HEIGHT,
    position: 'relative',
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loveButton: {
    position: 'absolute',
    top: 17,
    right: 42,
    width: 22,
    height: 22,
  },
  subsButton: {
    position: 'absolute',
    top: 16,
    left: 42,
    width: 26,
    height: 26,
  },
  nandezudButton: {
    position: 'absolute',
    top: 18,
    left: CONTENT_WIDTH / 2 - 68,
    width: 130,
    height: 23,
    zIndex: 10,
  },
  buttonImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  tryOnResultsContainer: {
    position: 'absolute',
    top: 70,
    left: 0,
    right: 0,
    zIndex: 56,
  },
  tryOnResultsContent: {
    paddingLeft: CONTENT_WIDTH * 0.25,
  },
  profileImage: {
    width: 200,
    height: 320,
    marginRight: 37,
    borderRadius: 10,
  },
  tryOnResultImage: {
    width: 210,
    height: 320,
    marginRight: 70,
    borderRadius: supportsClipPath ? 0 : 10,
    clipPath: supportsClipPath ? 'inset(0 round 10px)' : 'none',
    transform: [{ scaleX: 0.89 }],
  },
  errorText: {
    color: 'red',
    fontSize: 16,
    marginBottom: 10,
  },
  retryButton: {
    backgroundColor: '#007AFF',
    padding: 10,
    borderRadius: 5,
  },
  retryButtonText: {
    color: 'white',
    fontSize: 16,
  },
  deleteButton: {
    position: 'absolute',
    bottom: 12,
    right: 160,
    width: 112,
    backgroundColor: 'red',
    padding: 10,
    borderRadius: 20,
  },
  deleteButtonText: {
    color: 'white',
    fontSize: 18,
    fontWeight: '300',
    textAlign: 'center',
  },
  allButton: {
    position: 'absolute',
    top: 350,
    left: 37,
    width: 122,
    height: 275,
    zIndex: 50,
  },
  aiButton: {
    position: 'absolute',
    top: 352,
    left: 156,
    width: 102,
    height: 257,
    zIndex: 50,
  },
  meButton: {
    position: 'absolute',
    top: 538,
    left: 274,
    width: 103,
    height: 232,
    zIndex: 7,
  },
  newButton: {
    position: 'absolute',
    top: 358,
    left: 265,
    width: 120,
    height: 270,
    marginBottom: 20,
    zIndex: 7,
  },
  tryonButton: {
    position: 'absolute',
    top: 400,
    left: 43,
    width: 225,
    height: 507,
    zIndex: 7,
  },
  lottieContainer: {
    position: 'absolute',
    top: 400,
    left: 85,
    zIndex: 7,
  },
  lottieAnimation: {
    width: 225,
    height: 507,
  },
  userGreeting: {
    position: 'absolute',
    top: 600,
    left: 60,
    fontSize: 17,
    fontWeight: '300',
    color: 'white',
    zIndex: 8,
  },
  tryOnsRemainingText: {
    position: 'absolute',
    top: 630,
    left: 60,
    color: 'white',
    fontSize: 15,
    fontWeight: '300',
    zIndex: 8,
  },
});

export default UserScreen;