import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import roboImage from '../assets/images/ROBO.jpg';
import alloImage from '../assets/images/allo.jpg';
import buyImage from '../assets/images/BUY.jpg';
import aifImage from '../assets/images/aif.jpg';
import vafImage from '../assets/images/vaf.jpg';
import cashImage from '../assets/images/cash.jpg';
import vitImage from '../assets/images/vit.jpg';
import bradImage from '../assets/images/brad.jpg';
import scanImage from '../assets/images/scan.jpg';
import aboutImage from '../assets/images/about.jpg';

// Import komponenty pro obsah modálního okna
import TermsAndConditions from '../content/TermsAndConditions';
import PrivacyPolicy from '../content/PrivacyPolicy';
import FAQ from '../content/FAQ';

const uiElementSizes = {
  robo: { width: 400, height: 400 },
  allo: { width: 400, height: 400 },
  cash: { width: 200, height: 200 },
  userCount: { width: 60, height: 40 },
  loginButton: { width: 40, height: 5},
  signUpButton: { width: 40, height: 5 },
  subtitle: { width: 80, height: 10 },
  smallImage: { width: 400, height: 400 },
};

const uiElementPositions = {
  robo: { left: -3, top: -40 },
  allo: { right: -8, top: 370 },
  userCount: { left: 22, top: 11},
  loginButton: { left: 50, top: 43 },
  signUpButton: { left: 50, top: 50 },
  subtitle: { left: 50, top: 35 },
  buy: { right: -7, top: 160 },
  aif: { right: -12, top: 135 },
  vaf: { right: -11, top: 70 },
  cash: { right: -3, top: 940 },
  vit: { right: -2, top: 92 },
  brad: { right: -5, top: 187 },
  scan: { right: -9, top: 210 },
  about: { right: -20, top: 232 },
  contactForm: { left: 0, top: 2310 },
  footer: { left: 0, top: 3250 },
};

const FIXED_WIDTH = 420; // px
const FIXED_HEIGHT = 812; // px

// Komponenta Modal
const Modal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
}> = ({ isOpen, onClose, title, content }) => {
  if (!isOpen) return null;

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <h2 style={styles.modalTitle}>{title}</h2>
        <div style={styles.modalBody}>{content}</div>
        <button style={styles.modalCloseButton} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

const HomeScreen: React.FC = () => {
  const navigate = useNavigate();
  const [userCount, setUserCount] = useState(27800);
  const [displayCount, setDisplayCount] = useState(27800);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    company: '',
    contactNumber: '',
    message: ''
  });
  const [isHovered, setIsHovered] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<{ title: string; content: React.ReactNode }>({ title: '', content: null });

  useEffect(() => {
    const getDaysSinceStart = () => {
      const startDate = new Date('2024-10-06');
      const today = new Date();
      const diffTime = Math.abs(today.getTime() - startDate.getTime());
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    const seededRandom = (seed: number) => {
      const x = Math.sin(seed) * 10000;
      return x - Math.floor(x);
    };

    const calculateUserCount = () => {
      const days = getDaysSinceStart();
      let count = 47910;
      for (let i = 0; i < days; i++) {
        count += Math.floor(seededRandom(i) * (650 - 10 + 1) + 10);
      }
      return count;
    };

    setUserCount(calculateUserCount());
  }, []);

  useEffect(() => {
    const animateCount = () => {
      if (displayCount < userCount) {
        setDisplayCount(prevCount => {
          const diff = userCount - prevCount;
          const increment = Math.ceil(diff / 10);
          return Math.min(prevCount + increment, userCount);
        });
      }
    };

    const timer = setInterval(animateCount, 100);

    return () => clearInterval(timer);
  }, [userCount, displayCount]);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      select option {
        background-color: black;
        color: white;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px black inset !important;
        -webkit-text-fill-color: white !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const handleLogin = () => {
    navigate('/login');
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
   
    emailjs.send(
      'service_90rceci',
      'template_kczxy1q',
      formData,
      'aC-A40QG7SOL3kDXF'
    )
    .then((result) => {
      console.log('Email successfully sent!', result.text);
      alert('Your message has been sent successfully!');
      setFormData({
        name: '',
        email: '',
        subject: '',
        company: '',
        contactNumber: '',
        message: ''
      });
    }, (error) => {
      console.log('Failed to send email:', error.text);
      alert('Failed to send message. Please try again later.');
    });
  };

  const handleFooterButtonClick = (buttonName: string) => {
    let content: React.ReactNode;
    switch (buttonName) {
      case 'Terms and Conditions':
        content = <TermsAndConditions />;
        break;
      case 'Privacy Policy':
        content = <PrivacyPolicy />;
        break;
      case 'FAQ':
        content = <FAQ />;
        break;
      default:
        content = <p>Content not available.</p>;
    }
    setModalContent({ title: buttonName, content });
    setModalOpen(true);
  };

  return (
    <div style={styles.mainWrapper}>
      <main style={styles.main}>
        <div style={styles.scrollContainer}>
          <div style={styles.imageContainer}>
            <img
              src={roboImage}
              alt="ROBO"
              style={{
                ...styles.image,
                width: `${uiElementSizes.robo.width}px`,
                height: `${uiElementSizes.robo.height}px`,
                left: `${uiElementPositions.robo.left}%`,
                top: `${uiElementPositions.robo.top}px`,
              }}
            />
            <img
              src={alloImage}
              alt="ALLO"
              style={{
                ...styles.image,
                width: `${uiElementSizes.allo.width}px`,
                height: `${uiElementSizes.allo.height}px`,
                right: `${uiElementPositions.allo.right}%`,
                top: `${uiElementPositions.allo.top}px`,
              }}
            />
            <img
              src={buyImage}
              alt="BUY"
              style={{
                ...styles.image,
                width: `${uiElementSizes.smallImage.width}px`,
                height: `${uiElementSizes.smallImage.height}px`,
                right: `${uiElementPositions.buy.right}%`,
                top: `${uiElementPositions.buy.top}%`,
              }}
            />
            <img
              src={aifImage}
              alt="AIF"
              style={{
                ...styles.image,
                width: `${uiElementSizes.smallImage.width}px`,
                height: `${uiElementSizes.smallImage.height}px`,
                right: `${uiElementPositions.aif.right}%`,
                top: `${uiElementPositions.aif.top}%`,
              }}
            />
            <img
              src={vafImage}
              alt="VAF"
              style={{
                ...styles.image,
                width: `${uiElementSizes.smallImage.width}px`,
                height: `${uiElementSizes.smallImage.height}px`,
                right: `${uiElementPositions.vaf.right}%`,
                top: `${uiElementPositions.vaf.top}%`,
              }}
            />
            <img
              src={cashImage}
              alt="CASH"
              style={{
                ...styles.image,
                width: '380px',
                height: '380px',
                right: `${uiElementPositions.cash.right}%`,
                top: `${uiElementPositions.cash.top}px`,
              }}
            />
            <img
              src={vitImage}
              alt="VIT"
              style={{
                ...styles.image,
                width: `${uiElementSizes.smallImage.width}px`,
                height: `${uiElementSizes.smallImage.height}px`,
                right: `${uiElementPositions.vit.right}%`,
                top: `${uiElementPositions.vit.top}%`,
              }}
            />
            <img
              src={bradImage}
              alt="BRAD"
              style={{
                ...styles.image,
                width: '380px',
                height: '380px',
                right: `${uiElementPositions.brad.right}%`,
                top: `${uiElementPositions.brad.top}%`,
              }}
            />
            <img
              src={scanImage}
              alt="SCAN"
              style={{
                ...styles.image,
                width: `${uiElementSizes.smallImage.width}px`,
                height: `${uiElementSizes.smallImage.height}px`,
                right: `${uiElementPositions.scan.right}%`,
                top: `${uiElementPositions.scan.top}%`,
              }}
            />
            <img
              src={aboutImage}
              alt="ABOUT"
              style={{
                ...styles.image,
                width: '520px',
                height: '520px',
                right: `${uiElementPositions.about.right}%`,
                top: `${uiElementPositions.about.top}%`,
              }}
            />
            <p style={{
              ...styles.subtitle,
              width: `${uiElementSizes.subtitle.width}%`,
              left: `${uiElementPositions.subtitle.left}%`,
              top: `${uiElementPositions.subtitle.top}%`,
            }}>
              Your First AI-Powered Fashion Assistant
            </p>
            <button 
              style={{
                ...styles.button,
                width: `${uiElementSizes.loginButton.width}%`,
                height: `${uiElementSizes.loginButton.height}%`,
                left: `${uiElementPositions.loginButton.left}%`,
                top: `${uiElementPositions.loginButton.top}%`,
                zIndex: 1000,
              }} 
              onClick={handleLogin}
            >
              Login
            </button>
            <button 
              style={{
                ...styles.button,
                width: `${uiElementSizes.signUpButton.width}%`,
                height: `${uiElementSizes.signUpButton.height}%`,
                left: `${uiElementPositions.signUpButton.left}%`,
                top: `${uiElementPositions.signUpButton.top}%`,
                zIndex: 1000,
              }} 
              onClick={handleSignUp}
            >
              Sign Up
            </button>
            <div style={{
              ...styles.userCountContainer,
              width: `${uiElementSizes.userCount.width}%`,
              height: `${uiElementSizes.userCount.height}%`,
              left: `${uiElementPositions.userCount.left}%`,
              top: `${uiElementPositions.userCount.top}%`,
            }}>
              <h2 style={{
                ...styles.userCountTitle,
                fontSize: `${Math.min(uiElementSizes.userCount.width, uiElementSizes.userCount.height) * 0.2}px`,
              }}>
                TOTAL USERS
              </h2>
              <div style={styles.userCountDisplay}>
                {displayCount.toString().padStart(8, '0').split('').map((digit, index) => (
                  <span key={index} style={{
                    ...styles.digit,
                    fontSize: `${Math.min(uiElementSizes.userCount.width, uiElementSizes.userCount.height) * 0.3}px`,
                    padding: `${Math.min(uiElementSizes.userCount.width, uiElementSizes.userCount.height) * 0.05}px`,
                  }}>
                    {digit}
                  </span>
                ))}
              </div>
            </div>
            <ContactForm 
              onSubmit={handleSubmit}
              formData={formData}
              handleInputChange={handleInputChange}
              isHovered={isHovered}
              setIsHovered={setIsHovered}
              top={uiElementPositions.contactForm.top}
            />
            <Footer 
              handleFooterButtonClick={handleFooterButtonClick}
              top={uiElementPositions.footer.top}
            />
          </div>
        </div>
      </main>
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title={modalContent.title}
        content={modalContent.content}
      />
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  mainWrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
  },
  main: {
    width: `${FIXED_WIDTH}px`,
    height: `${FIXED_HEIGHT}px`,
    position: 'relative',
    overflow: 'hidden',
  },
  scrollContainer: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  imageContainer: {
    minHeight: '100%',
    width: '100%',
    position: 'relative',
  },
  image: {
    position: 'absolute',
    objectFit: 'contain',
  },
  subtitle: {
    position: 'absolute',
    transform: 'translateX(-50%)',
    color: 'white',
    fontSize: '16px',
    zIndex: 1,
    textAlign: 'center',
  },
  button: {
    position: 'absolute',
    transform: 'translateX(-50%)',
    border: '1px solid white',
    borderRadius: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
    zIndex: 3,
  },
  userCountContainer: {
    position: 'absolute',
    textAlign: 'center',
    color: 'white',
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userCountTitle: {
    marginBottom: '4px',
  },
  userCountDisplay: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    padding: '4px',
    borderRadius: '2px',
  },
  digit: {
    fontWeight: 'bold',
    margin: '0 1px',
    backgroundColor: 'transparent',
    border: '1px solid white',
    borderRadius: '1px',
    minWidth: '8px',
    color: 'white',
    textShadow: '0 0 2px rgba(255, 255, 255, 0.5)',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: '#000',
    padding: '20px',
    borderRadius: '10px',
    maxWidth: '80%',
    maxHeight: '80%',
    overflow: 'auto',
    color: '#fff',
    border: '1px solid #fff',
  },
  modalTitle: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  modalBody: {
    marginBottom: '20px',
  },
  modalCloseButton: {
    backgroundColor: 'transparent',
    color: '#fff',
    border: '1px solid #fff',
    padding: '10px 20px',
    cursor: 'pointer',
    fontSize: '16px',
    borderRadius: '5px',
  },
};

interface ContactFormProps {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  formData: {
    name: string;
    email: string;
    subject: string;
    company: string;
    contactNumber: string;
    message: string;
  };
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  isHovered: boolean;
  setIsHovered: React.Dispatch<React.SetStateAction<boolean>>;
  top: number;
}

const ContactForm: React.FC<ContactFormProps> = ({ 
  onSubmit, 
  formData, 
  handleInputChange, 
  isHovered, 
  setIsHovered,
  top
}) => {
  return (
    <div style={{
      position: 'absolute',
      top: `${top}px`,
      left: '48%',
      transform: 'translateX(-50%)',
      width: '70%',
      maxWidth: '280px',
      padding: '20px',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: 'white',
      borderRadius: '10px',
    }}>
      <h1 style={{ fontSize: '24px', marginBottom: '15px', textAlign: 'center' }}>get in touch</h1>
      <p style={{ marginBottom: '25px', fontSize: '16px', textAlign: 'center' }}>info@nandezu.com</p>
     
      <form onSubmit={onSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <div>
          <label htmlFor="name" style={{ marginBottom: '8px', display: 'block' }}>your name *</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            style={{
              width: '100%',
              padding: '12px',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              border: 'none',
              borderBottom: '1px solid #333',
              color: 'white',
              fontSize: '16px',
            }}
          />
        </div>
        <div>
          <label htmlFor="email" style={{ marginBottom: '8px', display: 'block' }}>email address *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            style={{
              width: '100%',
              padding: '12px',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              border: 'none',
              borderBottom: '1px solid #333',
              color: 'white',
              fontSize: '16px',
            }}
          />
        </div>
        <div>
          <label htmlFor="subject" style={{ marginBottom: '8px', display: 'block' }}>subject *</label>
          <select
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleInputChange}
            required
            style={{
              width: '100%',
              padding: '12px',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              border: 'none',
              borderBottom: '1px solid #333',
              color: 'white',
              fontSize: '16px',
              appearance: 'none',
            }}
          >
            <option value="">please choose an option</option>
            <option value="option1">Company cooperation</option>
            <option value="option2">User</option>
            <option value="option3">Influencer cooperation</option>
          </select>
        </div>
        <div>
          <label htmlFor="company" style={{ marginBottom: '8px', display: 'block' }}>Company name</label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '12px',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              border: 'none',
              borderBottom: '1px solid #333',
              color: 'white',
              fontSize: '16px',
            }}
          />
        </div>
        <div>
          <label htmlFor="contactNumber" style={{ marginBottom: '8px', display: 'block' }}>Contact number</label>
          <input
            type="tel"
            id="contactNumber"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleInputChange}
            style={{
              width: '100%',
              padding: '12px',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              border: 'none',
              borderBottom: '1px solid #333',
              color: 'white',
              fontSize: '16px',
            }}
          />
        </div>
        <div>
          <label htmlFor="message" style={{ marginBottom: '8px', display: 'block' }}>your message *</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            required
            style={{
              width: '100%',
              padding: '12px',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              border: 'none',
              borderBottom: '1px solid #333',
              color: 'white',
              fontSize: '16px',
              minHeight: '120px',
              resize: 'vertical',
            }}
          />
        </div>
       
        <button 
          type="submit" 
          style={{
            alignSelf: 'center',
            backgroundColor: isHovered ? 'white' : 'transparent',
            border: '2px solid white',
            color: isHovered ? 'black' : 'white',
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            transition: 'background-color 0.3s, color 0.3s',
            borderRadius: '5px',
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          let's go!
        </button>
      </form>
    </div>
  );
};

interface FooterProps {
  handleFooterButtonClick: (buttonName: string) => void;
  top: number;
}

const Footer: React.FC<FooterProps> = ({ handleFooterButtonClick, top }) => {
  return (
    <footer style={{
      position: 'absolute',
      top: `${top}px`,
      left: '0',
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: '#fff',
      padding: '40px 0 20px',
      borderTop: '1px solid #ffffff',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
      }}>
        <div style={{ margin: '0 20px', textAlign: 'center' }}>
          <p>Address: Werqwise San Mateo, CA 94402 4 West 4th Ave San Francisco CA</p>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
          paddingTop: '20px',
        }}>
          {['Terms and Conditions', 'Privacy Policy', 'FAQ'].map((buttonName) => (
            <button 
              key={buttonName}
              style={{
                backgroundColor: '#000000',
                color: '#ffffff',
                border: '1px solid #ffffff',
                borderRadius: '15px',
                padding: '4px 8px',
                margin: '0 10px',
                cursor: 'pointer',
                fontSize: '12px',
                width: '100px',  // Nastavení stejné šířky pro všechna tlačítka
              }} 
              onClick={() => handleFooterButtonClick(buttonName)}
            >
              {buttonName}
            </button>
          ))}
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <p>&copy; 2024 Nandezu Inc. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default HomeScreen;