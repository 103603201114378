import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';

import squareImage from '../assets/images/square.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #020209;
  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
`;

const SquareContainer = styled.div`
  position: absolute;
  top: -20px;
  left: 51%;
  transform: translateX(-50%);
`;

const Square = styled.img`
  width: 300px;
  height: 280px;
`;

const AppName = styled.h1`
  position: absolute;
  top: 270px;
  left: 50%;
  color: #fff;
  font-size: 30px;
  font-weight: 300;
  transform: translateX(-70px);
`;

const Subtitle = styled.p`
  position: absolute;
  top: 350px;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #fff;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  padding: 0 20px;
`;

const Input = styled.input`
  position: absolute;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 20px;
  height: 40px;
  border: none;
  border-bottom: 1px solid #fff;
  background: transparent;
  padding-left: 2px;
  outline: none;
  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const UsernameInput = styled(Input)`
  top: 430px;
`;

const PasswordInput = styled(Input)`
  top: 500px;
`;

const LoginButton = styled.button`
  position: absolute;
  top: 630px;
  left: 50%;
  width: 180px;
  height: 45px;
  border: 1px solid #fff;
  border-radius: 25px;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transform: translateX(-90px);
`;

const ForgotPasswordLink = styled.a`
  position: absolute;
  top: 560px;
  left: 50%;
  transform: translateX(30px);
  color: #fff;
  font-size: 15px;
  text-decoration: none;
`;

const SignUpLink = styled.a`
  position: absolute;
  top: 720px;
  left: 50%;
  transform: translateX(-25px);
  color: #fff;
  font-size: 15px;
  text-decoration: none;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  border: 1px solid white;
  max-width: 400px;
  width: 90%;
  text-align: center;
`;

const ModalText = styled.p`
  color: #333;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  background-color: #2450FF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
`;

export default function LoginScreen() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    try {
      console.log('Attempting login with:', { username, password: '*'.repeat(password.length) });
      
      const response = await fetch(`${BASE_URL}/user/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });

      console.log('Response status:', response.status);
      
      const data = await response.json();
      console.log('Response data:', data);

      if (response.ok) {
        console.log('Login successful, saving token');
        localStorage.setItem('userToken', data.token);
        localStorage.setItem('userId', data.user_id.toString());
        console.log('Token and userId saved, navigating to UserScreen');
        navigate(`/user/${data.user_id}`, { 
          state: { 
            token: data.token,
            userId: data.user_id
          } 
        });
      } else {
        console.log('Login failed:', data.error);
        alert(data.error || 'Please check your credentials and try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    setShowModal(true);
  };

  return (
    <Container>
      <ContentWrapper>
        <SquareContainer>
          <Square src={squareImage} alt="Square" />
        </SquareContainer>
        <AppName>NANDEZU</AppName>
        <Subtitle>Your First AI-Powered Fashion Assistant</Subtitle>
        <UsernameInput
          type="text"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          placeholder="Username"
        />
        <PasswordInput
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder="Password"
        />
        <LoginButton onClick={handleLogin} disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </LoginButton>
        <ForgotPasswordLink href="#" onClick={handleForgotPassword}>Forgot Password</ForgotPasswordLink>
        <SignUpLink href="#" onClick={() => navigate('/signup')}>Sign Up</SignUpLink>
      </ContentWrapper>
      
      {showModal && (
        <ModalOverlay>
          <ModalContent>
            <ModalText>
            To reset your password, please send your email and username to info@nandezu.com. 
            We will send you a password reset email with a new password.
            </ModalText>
            <CloseButton onClick={() => setShowModal(false)}>Close</CloseButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
}