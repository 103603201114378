import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import guide2Image from '../assets/images/Guide2.png';

const ScrollContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
`;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${guide2Image});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 70px; /* Space for the fixed button */
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
`;

const Button = styled.button`
  width: 180px;
  height: 45px;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 22px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 14px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
    transform: scale(1.05);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.7);
    transform: scale(0.95);
  }
`;

const GuideScreen2: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = (location.state as { userId?: string }) || {};

  const handleConfirm = () => {
    if (userId) {
      navigate('/guide-screen-3', { state: { userId } });
    } else {
      console.error('User ID is missing');
      // Zde můžete přidat další logiku pro případ, že userId chybí
    }
  };

  return (
    <ScrollContainer>
      <Container>
        {/* Your content goes here */}
      </Container>
      <ButtonContainer>
        <Button onClick={handleConfirm}>Confirm</Button>
      </ButtonContainer>
    </ScrollContainer>
  );
};

export default GuideScreen2;