// V ScrollContext.tsx

import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

interface ScrollPosition {
  [key: string]: number;
}

interface ScrollContextType {
  scrollPositions: ScrollPosition;
  setScrollPosition: (screen: string, position: number) => void;
}

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

export const ScrollProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [scrollPositions, setScrollPositions] = useState<ScrollPosition>(() => {
    // Načtení uložených pozic z localStorage při inicializaci
    const savedPositions = localStorage.getItem('scrollPositions');
    return savedPositions ? JSON.parse(savedPositions) : {};
  });

  useEffect(() => {
    // Uložení pozic do localStorage při každé změně
    localStorage.setItem('scrollPositions', JSON.stringify(scrollPositions));
  }, [scrollPositions]);

  const setScrollPosition = (screen: string, position: number) => {
    setScrollPositions(prev => ({
      ...prev,
      [screen]: position
    }));
  };

  return (
    <ScrollContext.Provider value={{ scrollPositions, setScrollPosition }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = (): ScrollContextType => {
  const context = useContext(ScrollContext);
  if (context === undefined) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
};