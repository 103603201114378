import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { SubscriptionProvider } from './contexts/SubscriptionContext';
import { ScrollProvider } from './contexts/ScrollContext';
import PCHomeScreen from './screens/PCHomeScreen';

// Screen imports
import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';
import SignUpScreen from './screens/SignupScreen';
import AfterRegistration from './screens/AfterRegistration';
import UploadPhoto from './screens/UploadPhoto';
import GuideScreen0 from './screens/GuideScreen0';
import GuideScreen1 from './screens/GuideScreen1';
import GuideScreen2 from './screens/GuideScreen2';
import GuideScreen3 from './screens/GuideScreen3';
import GuideScreen4 from './screens/GuideScreen4';
import GuideScreen5 from './screens/GuideScreen5';
import UserScreen from './screens/UserScreen';
import NewScreen from './screens/NewScreen';
import ProfileScreen from './screens/ProfileScreen';
import AllScreen from './screens/AllScreen';
import SubScreen from './screens/SubScreen';
import AIScreen from './screens/AIScreen';
import MyScreen from './screens/MyScreen';
import DressScreen from './screens/DressScreen';
import TopScreen from './screens/TopScreen';
import JeansScreen from './screens/JeansScreen';
import ManageProfilePicture from './screens/ManageProfilePicture';
import JumpsuitScreen from './screens/JumpsuitScreen';
import ShortScreen from './screens/ShortScreen';
import JacketScreen from './screens/JacketScreen';
import SkirtScreen from './screens/SkirtScreen';
import SweaterScreen from './screens/SweaterScreen';
import PantsScreen from './screens/PantsScreen';
import LegginsScreen from './screens/LegginsScreen';
import CoatScreen from './screens/CoatScreen';
import MenuScreen from './screens/MenuScreen';

const App: React.FC = () => {
  if (!isMobile) {
    return <PCHomeScreen />;
  }

  return (
    <ScrollProvider>
      <SubscriptionProvider>
        <Router>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/signup" element={<SignUpScreen />} />
            <Route path="/after-registration" element={<AfterRegistration />} />
            <Route path="/upload-photo" element={<UploadPhoto />} />
            <Route path="/guide-screen-0" element={<GuideScreen0 />} />
            <Route path="/guide-screen-1" element={<GuideScreen1 />} />
            <Route path="/guide-screen-2" element={<GuideScreen2 />} />
            <Route path="/guide-screen-3" element={<GuideScreen3 />} />
            <Route path="/guide-screen-4" element={<GuideScreen4 />} />
            <Route path="/guide-screen-5" element={<GuideScreen5 />} />
            <Route path="/user/:userId" element={<UserScreen />} />
            <Route path="/new-screen/:userId" element={<NewScreen />} />
            <Route path="/profile/:userId" element={<ProfileScreen />} />
            <Route path="/all-screen/:userId" element={<AllScreen />} />
            <Route path="/sub-screen/:userId" element={<SubScreen />} />
            <Route path="/ai-screen/:userId" element={<AIScreen />} />
            <Route path="/my-screen/:userId" element={<MyScreen />} />
            <Route path="/dress-screen/:userId" element={<DressScreen />} />
            <Route path="/top-screen/:userId" element={<TopScreen />} />
            <Route path="/jeans-screen/:userId" element={<JeansScreen />} />
            <Route path="/jumpsuit-screen/:userId" element={<JumpsuitScreen />} />
            <Route path="/short-screen/:userId" element={<ShortScreen />} />
            <Route path="/jacket-screen/:userId" element={<JacketScreen />} />
            <Route path="/skirt-screen/:userId" element={<SkirtScreen />} />
            <Route path="/sweater-screen/:userId" element={<SweaterScreen />} />
            <Route path="/pants-screen/:userId" element={<PantsScreen />} />
            <Route path="/leggins-screen/:userId" element={<LegginsScreen />} />
            <Route path="/coat-screen/:userId" element={<CoatScreen />} />
            <Route path="/manage-profile-picture/:userId" element={<ManageProfilePicture />} />
            <Route path="/menu-screen/:userId" element={<MenuScreen />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </SubscriptionProvider>
    </ScrollProvider>
  );
};

export default App;